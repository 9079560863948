import m from 'mithril'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {PanelMenu} from '@bitstillery/common/components'
import {Button} from '@bitstillery/common/components'
import {$t, api} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {StatisticsForFilterResponse} from '@/factserver_api/fact2server_api'

export class PanelMenuPortal extends PanelMenu {

    async oninit() {
        const {result, status_code} = await api.post<StatisticsForFilterResponse>('portal/filter/statistics', {
            offer_item_type: 'NEW_ARRIVALS',
            search_terms: [],
        }, true)
        if (status_code > 299) {
            return
        }

        $s.offers.special = result.offer_item_statistics ? result.offer_item_statistics[0].count : 0
        $s.offers.new_arrivals = result.offer_item_statistics ? result.offer_item_statistics[1].count : 0
    }

    slot_menu() {
        const btn = {
            align: 'left',
            tip_config: {
                placement: 'right',
            },
            type: 'default',
            variant: 'menu',
        }

        return <div className="menu">
            <Button
                {...btn}
                icon='dashboard'
                link='/dashboard'
                text={$t('page.dashboard')}
                tip={$t._('page.dashboard_tip')}
                type="brand"
            />

            <Button
                {...btn}
                active={$s.env.uri === '/offers' && !$s.env.qs.offertype}
                icon='storeSearch'
                link={get_route('/offers')}
                refresh={true}
                tip={$t._('page.browse_stock_tip')}
                text={$t('page.title.checkout-0')}
                type="brand"
            />
            <Button
                {...btn}
                active={$s.env.uri === '/offers' && $s.env.qs.offertype === 'SPECIALS'}
                icon="specialOffers"
                link={get_route('/offers', {offertype: 'SPECIALS'})}
                refresh={true}
                tip={$t._('offer.special_tip')}
                text={$s.offers.special ? $t('offer.special_count', {special: $s.offers.special}) : $t('offer.special')}
                type="brand"
            >
                {$s.offers.special > 0 && (
                    <div className="amount">
                        {$s.offers.special}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={$s.env.uri === '/offers' && $s.env.qs.offertype === 'NEW_ARRIVALS'}
                align='left'
                icon='newArrivals'
                link={get_route('/offers', {
                    offertype: 'NEW_ARRIVALS',
                    sort_by: 'entry_date',
                    sort_order: 'desc',
                })}
                refresh={true}
                tip={$t._('offer.new_arrivals_tip')}
                text={$t('offer.new_arrivals')}
                type="brand"
            >
                {$s.offers.new_arrivals > 0 && (
                    <div className="amount">
                        {$s.offers.new_arrivals}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={$s.env.uri === '/offers' && $s.env.qs.offertype === 'FAVORITES'}
                disabled={$s.identity.demo}
                icon='favorite-o'
                link={get_route('/offers', {offertype: 'FAVORITES'})}
                refresh={true}
                tip={$t._('offer.my_favourites_tip')}
                text={$t('offer.my_favourites')}
                type="brand"
            />

            <hr/>

            <Button
                {...btn}
                active={$s.env.uri === `/orders/${$s.cart.artkey}/checkout`}
                className='btn-orders'
                icon='cartRight'
                link={`/orders/${$s.cart.artkey}/checkout?step=1`}
                text={$t('cart.checkout', {count: 0})}
                tip={$t._('cart.checkout_tip')}
                type="brand"
            >
                {$m.cart.cart_units() > 0 && (
                    <div className="amount">
                        {$m.cart.cart_units()}
                    </div>
                )}
            </Button>

            <Button
                {...btn}
                active={$s.env.uri.startsWith('/orders') && !$s.env.uri.startsWith(`/orders/${$s.cart.artkey}/checkout`)}
                icon='myOrders'
                link="/orders"
                text={$t('page.my_orders')}
                tip={$t._('page.my_orders_tip')}
                type="brand"
            />

            <Button
                {...btn}
                active={m.route.get().startsWith('/my-portal')}
                className={classes('btn-settings', {
                    'btn-highlight': $s.promotion.active,
                })}
                icon={$s.promotion.active ? $s.promotion.icon : 'settings'}
                link={$s.promotion.active ? '/my-portal?tabId=promotions' : '/my-portal?tabId=profile'}
                text={$s.promotion.active ? $s.promotion.name : $t('page.my_portal')}
                tip={() => {
                    if ($s.promotion.active) return $t('page.my_portal_tip_promotion')
                    return $t('page.my_portal_tip')
                }}
                type="brand"
            >{$s.promotion.active && <div className="highlight-tag">{$t('promotions.label.promotion')}!</div>}</Button>
        </div>
    }
}
