import {
    AdditionalSalesOrderDetailsResponse,
    PortalOrderItemResponse,
    SalesOrderAdditionalResponse,
    SalesOrderCreditItemResponse,
    SalesOrderDetailsResponse,
    SalesOrderItemResponse,
    SalesOrderTBOItemResponse,
} from '@/factserver_api/fact2server_api'

export interface OrderType {
    sales_order: SalesOrderDetailsResponse
    portal_order_items: PortalOrderItemResponse[]
    sales_order_additionals: SalesOrderAdditionalResponse[]
    sales_order_items: SalesOrderItemResponse[]
    sales_order_tbo_items: SalesOrderTBOItemResponse[]
    sales_order_credit_items: SalesOrderCreditItemResponse[]
    invoice_details: AdditionalSalesOrderDetailsResponse
}

export function order_to_pdf_sales_order(order: OrderType) {
    const sois = order.sales_order_items.map((soi) => {
        return {
            artkey: soi.artkey,
            item: {
                case: {
                    article_code: soi.case_article_code,
                    artkey: soi.case_artkey,
                    best_before_date: soi.item_best_before_date,
                    bottle: {
                        alcohol_percentage: soi.bottle_alcohol_percentage,
                        artkey: soi.bottle_artkey,
                        cbs_code: soi.bottle_cbs_code,
                        product: {
                            name: soi.product_name,
                        },
                        refill_status: soi.bottle_refill_status || '',
                        volume: soi.bottle_volume,
                    },
                    customs_status: soi.case_customs_status,
                    gift_box_type: soi.case_gift_box_type,
                    number_of_bottles: soi.case_number_of_bottles,
                    tax_label: soi.case_tax_label,

                },
                country_of_origin: '', // unused on the proforma / invoice
                lot: '', // unused on the proforma / invoice
                bottle_lot: soi.item_bottle_lot,
            },
            number_of_bottles: 0, // unused on the proforma / invoice
            number_of_cases: soi.number_of_cases,
            price_per_case: soi.price_per_case,
            total_was_sold_for: soi.total_was_sold_for,
            vat_percentage: soi.vat_percentage,
            vat_total: soi.vat_total,
        }
    })

    const tbos = order.sales_order_tbo_items.map((tbo) => {
        return {
            artkey: tbo.artkey,
            case: {
                article_code: tbo.case_article_code,
                artkey: tbo.case_artkey,
                best_before_date: tbo.item_best_before_date,
                bottle: {
                    alcohol_percentage: tbo.bottle_alcohol_percentage,
                    artkey: tbo.bottle_artkey,
                    cbs_code: tbo.bottle_cbs_code,
                    product: {
                        name: tbo.product_name,
                    },
                    refill_status: tbo.bottle_refill_status || '',
                    volume: tbo.bottle_volume,
                },
                customs_status: tbo.case_customs_status,
                gift_box_type: tbo.case_gift_box_type,
                number_of_bottles: tbo.case_number_of_bottles,
                tax_label: tbo.case_tax_label,

            },
            number_of_cases: tbo.number_of_cases,
            price_per_case: tbo.price_per_case,
            total_was_sold_for_incl_excise: tbo.total_was_sold_for_incl_excise,
            vat_percentage: tbo.vat_percentage,
            vat_total: tbo.vat_total,

        }
    })

    const credit_items = order.sales_order_credit_items.map((credit) => {
        return {
            artkey: credit.artkey,
            number_of_cases: credit.number_of_cases,
            sales_order_item: {
                artkey: credit.original_sales_order_artkey,
                item: {
                    case: {
                        article_code: credit.case_article_code,
                        artkey: credit.case_artkey,
                        best_before_date: credit.item_best_before_date,
                        bottle: {
                            alcohol_percentage: credit.bottle_alcohol_percentage,
                            artkey: credit.bottle_artkey,
                            cbs_code: credit.bottle_cbs_code,
                            product: {
                                name: credit.product_name,
                            },
                            refill_status: credit.bottle_refill_status || '',
                            volume: credit.bottle_volume,
                        },
                        customs_status: credit.case_customs_status,
                        gift_box_type: credit.case_gift_box_type,
                        number_of_bottles: credit.case_number_of_bottles,
                        tax_label: credit.case_tax_label,

                    },
                    country_of_origin: '', // not used in the proforma,
                    lot:  '', // not used in the proforma,

                },
                number_of_bottles: 0, // not used in the proforma,
                number_of_cases: credit.number_of_cases,
                price_per_case: credit.original_price_per_case,
                vat_per_case: credit.original_vat_per_case,
                vat_percentage: credit.original_vat_percentage,
                vat_total: credit.original_vat_total,
            },
        }
    })

    const additionals = order.sales_order_additionals.map((additional) => {
        return {
            artkey: additional.artkey,
            description: additional.description,
            price_per_unit: additional.price_per_unit,
            quantity: additional.quantity,
            total: additional.total,
            total_value: additional.total,
            vat_percentage: additional.vat_percentage,
        }
    })

    return {
        artkey: order.sales_order.artkey,
        destination: order.invoice_details.destination,
        destination_location: '',
        includes_excise: order.sales_order.includes_excise,
        incoterm: order.sales_order.incoterm,
        incoterm_location: order.sales_order.incoterm_location,
        invoice_number: order.sales_order.invoice_number,
        is_invoiced_on: order.sales_order.is_invoiced_on,
        reference: order.sales_order.reference,
        sales_order_additionals: additionals,
        sales_order_credit_items: credit_items,
        sales_order_items: sois,
        sales_order_tbo_items: tbos,
        supplier: order.invoice_details.supplier,
        vat_code: order.invoice_details.vat_code,
        vat_total: order.sales_order.vat_total,
        was_sold_for: order.sales_order.was_sold_for,
        was_sold_for_incl_vat: order.sales_order.was_sold_for_incl_vat,
        was_sold_in: order.sales_order.was_sold_in,
    }
}
