let api_host, api_host_new, hostname, product_photo_host, sentry_dsn, sentry_dist, tawk_id

api_host = '/api/v1'
api_host_new = '/api/v2'

if (process.env.BUN_ENV === 'development') {

    hostname = window.location.hostname

    product_photo_host = 'https://photos.staging.v5.springtimegroup.nl'
    sentry_dsn = process.env.MSI_TEST_SENTRY ? process.env.MSI_SENTRY_DSN : ''
    tawk_id = process.env.MSI_TEST_TAWK ? process.env.MSI_TAWK_ID : ''

} else if (process.env.BUN_ENV === 'staging') {
    hostname = 'staging.v5.springtimegroup.nl'

    product_photo_host = `https://photos.${hostname}`
    sentry_dsn = process.env.MSI_SENTRY_DSN
    tawk_id = process.env.MSI_TAWK_ID
    sentry_dist = `${process.env.MSI_SENTRY_DIST}-${process.env.BUN_ENV}` // portal-a2bc-staging or discover-msi-production

} else if (process.env.BUN_ENV === 'production') {
    hostname = 'movingspirits.nl'

    product_photo_host = `https://photos.${hostname}`
    sentry_dsn = process.env.MSI_SENTRY_DSN
    tawk_id = process.env.MSI_TAWK_ID
    sentry_dist = `${process.env.MSI_SENTRY_DIST}-${process.env.BUN_ENV}` // portal-a2bc-staging or discover-msi-production
}

export const config = {
    account_name: process.env.MSI_TITLE,
    api_host,
    api_host_new,
    api_password: '4Er5HsfLDdmjLHcc',
    api_user: 'ms-portal',
    env: process.env.BUN_ENV,
    hostname,
    product_photo_host,
    sentry_dsn,
    sentry_dist,
    tawk_id,
    websiteUrl: process.env.MSI_WEBSITE,
}
