import m from 'mithril'
import {Changed, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {to_specs} from '@bitstillery/common/lib/specs'
import {get_route} from '@bitstillery/common/lib/utils'
import {$t, api} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {PortalOfferItem} from '@/factserver_api/fact2server_api'

export class SpotlightWidget extends MithrilTsxComponent<any> {

    selectedItem: PortalOfferItem | null = null
    // Seconds to update the spotlight items
    spotlightIntervalTime = 60
    spotlightInterval: number | undefined = undefined

    data = proxy({
        loading: false,
        spotlights: [] as PortalOfferItem[],
    })

    async load_spotlight() {
        this.data.loading = true
        const {result, status_code} = await api.get<PortalOfferItem[]>('portal/offer-items/spotlight')
        if (status_code === 404) {
            $s.portal.ready = false
            return
        }
        if (status_code > 299) {
            return
        }

        this.data.spotlights = result
        const artkeys = this.data.spotlights.map((item) => item.artkey)
        const artkeys_related = this.data.spotlights.filter((item) => item.related_offer_item_artkey).map((item) => item.related_offer_item_artkey) as any
        await Promise.all([
            $m.offer.load_product_photos(artkeys.concat(artkeys_related)),
            $m.offer.load_country_of_origin(artkeys.concat(artkeys_related)),
        ])

        this.data.loading = false
    }

    oncreate() {
        // If logged in...
        if ($s.identity.token) {
            // ...do the spotlight API call. The check on logged in is needed
            // because of the timeout which would otherwise still trigger an
            // API call after the user logged out in the meanwhile.
            this.spotlightInterval = window.setInterval(() => {
                this.load_spotlight()
            }, this.spotlightIntervalTime * 1000)
            this.load_spotlight()
        }
    }

    onremove() {
        clearTimeout(this.spotlightInterval)
    }

    view(_vnode: m.Vnode<any>) {
        return <div className="c-spotlight-widget">
            <div className="items">
                {(() => {
                    if (this.data.loading) {
                        return (
                            <div className="widget-loading placeholder-d"><Spinner/></div>
                        )
                    } else {
                        return this.data.spotlights.map((offer_item) => {
                            const offer_item_price = $m.offer.offer_item_price(offer_item)
                            return <div className="item" onclick={() => {this.selectedItem = offer_item}}>
                                <div className="image" onclick={() => {
                                    $s.carousel.selection = offer_item.artkey
                                }}>
                                    <div className="c-offer-price-ribbon">
                                        {$t('offer.best_deal')}
                                    </div>
                                    <img src={$m.offer.primary_photo(offer_item?.artkey)}/>
                                </div>
                                <div className="product-info" onclick={() => {
                                    m.route.set(get_route('/offers', {search: encodeURI(offer_item.product_name)}))
                                }}>
                                    <Changed
                                        previous={offer_item_price.previous}
                                        current={offer_item_price.current}
                                        currency={offer_item.currency}
                                        hint={true}
                                        validity={offer_item.validity_date}
                                    />
                                    <div className="specs">
                                        {offer_item.product_name}
                                        <div className="specs">{to_specs(offer_item)}</div>
                                        {($s.identity.user.show_excise && offer_item_price.excise > 0.01) && <div className='excise'>
                                            <span>* {$s.identity.user.includes_excise ?
                                                $t('excise.including', {amount: format_money_responsive(offer_item_price.excise, false, offer_item.currency)}) :
                                                $t('excise.excluding', {amount: format_money_responsive(offer_item_price.excise, false, offer_item.currency)})}
                                            </span>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                })()}
            </div>
        </div>
    }
}
