import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$t} from '@bitstillery/common/app'

import {$m, $s} from '@/app'

export class IncentiveTransport extends MithrilTsxComponent<any> {

    view() {
        const min_order = Number($s.identity.user.minimum_order_amount || 0)
        const free_shipping = min_order - $m.cart.total_amount_no_vat()
        const text = $t('registration.warehouse.shipping.incentive', {
            amount: `${format_money_responsive(free_shipping)} ${$s.identity.user.currency}`,
            threshold: `${format_money_responsive(min_order)} ${$s.identity.user.currency}`,
        })

        return (
            <div className="c-incentive transport">
                <div className="incentive-header">
                    <Icon name='sale' size='l' tip={text} />
                </div>
                <div className="text">
                    {free_shipping > 0 && m.trust(text)}
                    {free_shipping <= 0 && <div className="text">
                        {$t('registration.warehouse.shipping.free_shipping')}
                    </div>}
                </div>

            </div>
        )
    }
}
