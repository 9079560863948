import {proxy} from '@bitstillery/common/lib/proxy'
import {debounce} from '@bitstillery/common/lib/utils'
import {$t, api, events, logger, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'

// A timeout id to keep track of highlighted basket item state.
let CART_CHANGED:any = null
let CART_API_DEBOUNCES = {}

export const VALIDATION_ICONS = {
    api: 'danger',
    less_available: 'stock',
    minimum_quantity: 'stock',
    price_up: 'raised',
    unavailable: 'stock',
}

export const VALIDATION_TYPES = {
    api: 'error',
    less_available: 'warning',
    minimum_quantity: 'warning',
    price_up: 'warning',
    unavailable: 'warning',
}

export interface CartItemState {
    artkey: string | null
    bottle_alcohol_percentage: string
    bottle_refill_status: string
    bottle_volume: string
    case_artkey: number
    case_customs_status: string
    case_gift_box_type: string
    case_number_of_bottles: number
    excise_per_case: number
    list_price: number
    number_of_cases: number
    offer_item_artkey: number
    offer_item_type: string
    portal_comment: string
    product_name: string
    unit: 'case' | 'bottle'
    value: number
    vat_percentage: string
}

/**
 * Add the state of a new cart item to the store.
 * The attributes here are expected to come either from an
 * offer_item (in case when an offer_item is added to the state) or
 * from a portal_order_item, in case the order is reloaded.
 */
export function add_cart_item_state(state:CartItemState) {

    if (state.number_of_cases) {
        // A portal_order_item has number_of_cases
        if ($s.identity.user.price_preference === 'case') {
            state.value = state.number_of_cases
        } else {
            state.value = state.number_of_cases * state.case_number_of_bottles
        }
    } else {
        if ($s.identity.user.price_preference === 'case') {
            state.number_of_cases = state.value
        } else {
            state.number_of_cases = state.value / state.case_number_of_bottles
        }
    }

    $s.cart.items[state.case_artkey] = state
}

export function booked_amount() {
    return $s.cart.sales_order_items
        .map((i:any) => i.number_of_cases * (Number(i.price_per_case)))
        .reduce((a, b) => a + b, 0)
}
export function booked_bottles() {
    return $s.cart.sales_order_items.map((i:any) => i.number_of_cases * i.case_number_of_bottles).reduce((a, b) => a + b, 0)
}

export function booked_cases() {
    return $s.cart.sales_order_items.map((i:any) => i.number_of_cases).reduce((a, b) => a + b, 0)
}

export function cart_amount(validate = false) {
    return Object.values($s.cart.items).map((i:any) => {
        if (validate && $s.cart.errors.price_up[i.artkey]) {
            return i.number_of_cases * $s.cart.errors.price_up[i.artkey].list_price
        }

        return i.number_of_cases * Number(i.list_price)
    }).reduce((a, b) => a + b, 0)
}

export function cart_bottles() {
    return Object.values($s.cart.items).map((i:any) => i.number_of_cases * i.case_number_of_bottles).reduce((a, b) => a + b, 0)
}

export function cart_cases() {
    return Object.values($s.cart.items).map((i:any) => i.number_of_cases).reduce((a, b) => a + b, 0)
}

export function cart_units() {
    if ($s.cart.show_bottles) {
        return cart_bottles()
    } else {
        return cart_cases()
    }
}

export const data = proxy({
    stepper: {
        _direction: () => $s.panels.context.collapsed ? 'vertical' : 'horizontal',
        selection: 0,
    },
})

export function has_errors() {
    return Object.values($s.cart.errors).some((i) => Object.keys(i).length)
}

/**
 * Endpoint:
 *  - order_artkey (optional int): Sales Order to add the item to, if None add it to an empty order.
 *  - offer_item_artkey (int): Offer item artkey.
 *  - number_of_cases (int): Number of cases to add.
 *  - price_per_case (numeric): Price per case as shown to the customer. Might include excise.
 *  - excise_per_case (numeric): Excise per case for this case.
 *  - portal_comment (str): Comment for this item.
 */
const update_cart_item_api = async function(cart_api_data) {
    // Note: if order_artkey is null, then this is the "new order".
    // After adding the first item to the basket, this API call
    // will return the order artkey.
    $s.cart.loading = true
    try {
        const {result, success} = await api.post('basket.modify_item', cart_api_data) as any
        if (result && cart_api_data.order_artkey === null) {
            logger.info(`[cart] order ${result.order_artkey} created`)
            await $m.order.load_current_order()
        }

        logger.info(`[cart] update api: ${cart_api_data.case_artkey}/${cart_api_data.number_of_cases}`)
        if (result === null) return // deletion
        if (!success) {
            throw new Error(`cart update api failed: ${JSON.stringify(result)}`)
        }
        // set_cart_item_state doesn't have a PortalOrderItem artkey yet until the api returns.
        if (!$s.cart.items[cart_api_data.case_artkey].artkey) {
            $s.cart.items[cart_api_data.case_artkey].artkey = result.artkey
        }
    } catch (err) {
        $s.cart.errors.api[cart_api_data.case_artkey] = true
        notifier.notify($t('notifications.cart_api_error'), 'danger', 20000)
    }

    $s.cart.loading = false
}

export function total_amount(validate = false) {
    return total_amount_no_vat(validate) + vat_total(validate)
}

export function total_amount_no_vat(validate = false) {
    let total = cart_amount(validate) + booked_amount()
    const voucher = voucher_additional() as any
    if (voucher) {
        total += Number(voucher.total)
    }

    return total
}

/**
 * Update the state of the modified cart, and optionally propagate
 * the updated change to the backend, when the debounce timer is
 * not triggered again.
 */
export async function update_cart(cart_item_data:any, case_artkey: number, debounce_action = true) {
    const cart_item = $s.cart.items[case_artkey]

    if (!cart_item_data.order_artkey) cart_item_data.order_artkey = $s.cart.artkey
    if (typeof cart_item_data.number_of_cases !== 'number') cart_item_data.number_of_cases = cart_item.number_of_cases
    if (typeof cart_item_data.portal_comment !== 'string') cart_item_data.portal_comment = cart_item.portal_comment

    cart_item.number_of_cases = cart_item_data.number_of_cases
    cart_item_data.excise_per_case = cart_item.excise_per_case
    cart_item_data.offer_item_artkey = cart_item.offer_item_artkey
    cart_item_data.case_artkey = case_artkey

    // Use the default price from the orderitem if no price is passed.
    if (!cart_item_data.price_per_case) cart_item_data.price_per_case = cart_item.list_price

    if (cart_item.number_of_cases === 0) {
        logger.debug(`[cart] delete item ${case_artkey} from cart state`)
        delete $s.cart.items[case_artkey]
    } else {
        if (CART_CHANGED) clearTimeout(CART_CHANGED)
        CART_CHANGED = setTimeout(() => {
            $s.cart.item_changed = null
        }, 3000)
        // Variable used to highlight newly added items.
        $s.cart.item_changed = cart_item.case_artkey
    }

    $m.order.active_promotion_status()

    // The first added item will create and load a new order, and must go through the API.
    if (!debounce_action || !$s.cart.artkey) {
        await update_cart_item_api(cart_item_data)
    } else {
        if (!CART_API_DEBOUNCES[case_artkey]) {
            CART_API_DEBOUNCES[case_artkey] = debounce(1000, update_cart_item_api)
        }
        await CART_API_DEBOUNCES[case_artkey](cart_item_data)
    }
}

export async function validate_cart(validation_data) {
    if (!validation_data.items_errors) return
    logger.debug(`[cart] validating cart errors: ${Object.keys(validation_data.items_errors).length}`)

    const errors = Object.entries(validation_data.items_errors) as any
    for (const [cart_item_id, error] of errors) {
        const cart_item = $s.cart.items[cart_item_id]
        const offer_item = $s.cart.offer_items[cart_item_id]

        if ('maximum_quantity' in error) {
            if (error.maximum_quantity === 0) {
                $s.cart.errors.unavailable[cart_item_id] = true
            } else {
                $s.cart.errors.less_available[cart_item_id] = error.maximum_quantity
            }

            // Update ProductQuantity widget to the maximum quantity.
            cart_item.value = $m.offer.unit_amount(error.maximum_quantity, cart_item.case_number_of_bottles)
            cart_item.list_quantity = error.maximum_quantity

            if (offer_item) {
                offer_item.list_quantity = error.maximum_quantity
            }
        }

        if ('list_price' in error && (!('maximum_quantity' in error) || error.maximum_quantity > 0)) {
            // Only warn about the product cart price if the product is available at all;
            // otherwise we just warn about the product being unavailable.
            const new_price = error.list_price.list_price
            const basket_price = error.list_price.basket_price
            if (parseFloat(new_price) > parseFloat(basket_price)) {
                $s.cart.errors.price_up[cart_item_id] = error.list_price
            }
        }

        if ('minimum_quantity' in error) {
            $s.cart.errors.minimum_quantity[cart_item_id] = error.minimum_quantity
            // Update ProductQuantity widget to the minimum quantity.
            cart_item.value = $m.offer.unit_amount(error.minimum_quantity, cart_item.case_number_of_bottles)
            cart_item.minimum_quantity = error.minimum_quantity
            if (offer_item) {
                offer_item.minimum_quantity = error.minimum_quantity
            }
        }
    }
}

export async function validate_cart_fix() {
    const errors = $s.cart.errors
    let reload_order = Object.values(errors).some((i) => Object.values(i).length)
    let fix_promises = [] as any

    for (const [error_category, error_entries] of Object.entries(errors)) {
        const entries = Object.entries(error_entries)
        fix_promises = [...fix_promises, ...entries.map(([sales_order_item_id, errorValue]:any) => {
            const sales_order_item = $s.cart.items[sales_order_item_id]
            if (error_category === 'price_up') {
                const new_price = errorValue.list_price
                return update_cart({
                    price_per_case: new_price,
                }, sales_order_item.case_artkey, false)
            } else if (error_category === 'less_available') {
                return $m.cart.update_cart({
                    number_of_cases: errorValue,
                }, sales_order_item.case_artkey, false)
            } else if (error_category === 'minimum_quantity') {
                return $m.cart.update_cart({
                    number_of_cases: errorValue,
                }, sales_order_item.case_artkey, false)
            } else if (error_category === 'unavailable') {
                return $m.cart.update_cart({
                    number_of_cases: 0,
                }, sales_order_item.case_artkey, false)
            }
        })]
    }

    await Promise.all(fix_promises)
    // Clear validation errors.
    for (const [error_category, error_entries] of Object.entries(errors)) {
        for (const sales_order_item_id of Object.keys(error_entries)) {
            delete errors[error_category][sales_order_item_id]
        }
    }
    if (reload_order) {
        await $m.order.load_current_order()
    }
}

export function validate_cart_reset(artkey, quantity = false, price = false) {
    const errors = $s.cart.errors
    logger.debug('[cart] reset cart validation state')
    if (quantity) {
        delete errors.less_available[artkey]
        delete errors.minimum_quantity[artkey]
        delete errors.unavailable[artkey]
    }

    if (price) {
        delete errors.price_up[artkey]
    }
}

export function vat_booked() {
    return $s.cart.sales_order_items
        .map((i:any) => (i.number_of_cases * Number(i.price_per_case) * (Number(i.vat_percentage) / 100)))
        .reduce((a, b) => a + b, 0)
}

export function vat_cart(validate = false) {
    if (!$s.cart.includes_excise) {
        return 0
    }
    return Object.values($s.cart.items)
        .map((i:any) => {
            let price
            if (validate && $s.cart.errors.price_up[i.artkey]) {
                price = i.number_of_cases * Number($s.cart.errors.price_up[i.artkey].list_price)
            } else {
                price = i.number_of_cases * Number(i.list_price)
            }

            return price * (Number(i.vat_percentage) / 100)
        })
        .reduce((a, b) => a + b, 0)
}

export function vat_total(validate = false) {
    if (!$s.cart.includes_excise) {
        return 0
    }
    let total = vat_booked() + vat_cart(validate)
    const voucher = voucher_additional() as any
    if (voucher) {
        if (voucher.value_type === 'PERCENTAGE') {
            total = total + (total * Number(voucher.value_per_quantity) / 100)
        }
    }

    return total
}

export function voucher_additional() {
    return $s.cart.sales_order_additionals.find((i:any) => i.sales_order_additional_type === 'voucher')
}

export async function init() {
    events.on('active-order.set-order', () => {
        CART_API_DEBOUNCES = {}
    })
}
