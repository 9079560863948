import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Spinner} from '@bitstillery/common/components'
import {get_route} from '@bitstillery/common/lib/utils'
import {format_iso_to_date, format_iso_to_fixed_date_format} from '@bitstillery/common/lib/format'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, config, api} from '@bitstillery/common/app'

import {DialogOffer} from './dialog_offer'

import {$s} from '@/app'
import {DashboardWidget} from '@/components/dashboard/widget'

export class DashboardOffersWidget extends MithrilTsxComponent<any> {

    data = proxy({
        _dialog_offer_active: () => {
            const promotion_popup_active = $s.promotion.active && !dayjs().isSame(dayjs($s.dialog.promotion), 'day')
            const offer_popup_active = this.data.offers && (!$s.dialog.offer || !dayjs().isSame(dayjs($s.dialog.offer), 'day'))
            return !promotion_popup_active && offer_popup_active
        },
        dialog_offer: null,
        loading: true,
        offers: [],
    })

    async oncreate() {
        const {result, status_code}: {result: any; status_code: number} = await api.post('offer.active-offers')
        if (status_code > 299) {
            return
        }

        this.data.offers.splice(0, this.data.offers.length, ...result)
        const offers_with_popups = this.data.offers
            .filter((offer) => offer.offer_portal_popup_image_s3_key !== null)
            .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())
            .reverse()

        for (const offer_to_popup of offers_with_popups) {
            if (offer_to_popup.offer_portal_popup_title && offer_to_popup.offer_portal_popup_image_s3_key) {
                this.data.dialog_offer = offer_to_popup
                break
            }
        }

        this.data.loading = false

    }

    view() {
        return (
            <DashboardWidget
                icon="specialOffer"
                name="offers"
                title={$t('offer.personal')}
            >
                {this.data.dialog_offer && this.data._dialog_offer_active && (
                    <DialogOffer offer={this.data.dialog_offer} onclose={() => {
                        this.data.dialog_offer = null
                    }}/>
                )}
                {this.data.offers.length ? (
                    <div class="widget-list">
                        {this.data.offers.map((offer) => (
                            <OfferRow offer={offer} />
                        ))}
                    </div>
                ) : (
                    <div class="c-empty-message">
                        {this.data.loading ? <Spinner/> : $t('offer.no_personal')}
                    </div>
                )}
            </DashboardWidget>
        )
    }
}

export class OfferRow extends MithrilTsxComponent<any> {

    async download_offer(offer) {
        await api.download(
            `portal/offer/${offer.artkey}/price-list`,
            `${config.account_name} ${offer.title} - ${format_iso_to_fixed_date_format(new Date().toISOString())}.xlsx`,
            {},
        )
    }

    view(vn: m.Vnode<any>) {
        return <div className="widget-item" key={vn.attrs.offer.artkey}>
            <div className="info fl-1">
                <div className="bold">{vn.attrs.offer.title}</div>
                <div className="text-light">
                    {$t('offer.valid_until', {date: format_iso_to_date(vn.attrs.offer.end_date, $s.language), interpolation: {escapeValue: false}})}
                </div>
            </div>
            <div className="actions">
                <Button
                    link={get_route('/offers', {offer_hash: vn.attrs.offer.hash})}
                    tip={$t('offer.view_offer')}
                    type="default"
                    icon="stock"
                />

                <Button
                    onclick={() => this.download_offer(vn.attrs.offer)}
                    tip={$t('offer.download_offer')}
                    type="default"
                    icon="download"
                />
            </div>
        </div>
    }
}
