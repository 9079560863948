import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    CellBottleAndCase,
    CollectionHeader,
    CollectionItems,
    CollectionView,
} from '@bitstillery/common/components'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {format_iso_to_date, format_money_with_symbol} from '@bitstillery/common/lib/format'
import {$t, config} from '@bitstillery/common/app'

import {$m, $s} from '@/app'

const filters = generate_filters({})

const transforms:CollectionTransforms = {
    filters_to_query: () => {
        return {
            sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
            sort_by: collection.state.sort.by,
        }
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: () => $t('filters.sort.types.reference'),
        render: (row) => row.reference,
    }, {
        name: () => $t('filters.sort.types.portal_status'),
        render: (order) => {
            // Can only continue with order that are PENDING
            // and are in our native currency.
            if ([$m.order.OrderStatus.PENDING, $m.order.OrderStatus.NEW].includes(order.portal_status) && order.currency === $s.identity.user.currency) {
                const is_current = ($s.cart.artkey === order.artkey)
                if (is_current) {
                    return (<div>{$t('orders.status_active')}</div>)
                }
                return (<div>{$t('orders.status_open')}</div>)
            } else {
                const label_map = {
                    [$m.order.OrderStatus.INVOICED]: 'danger',
                    [$m.order.OrderStatus.CONFIRMED]: 'success',
                    [$m.order.OrderStatus.FINALIZED]: 'warning',
                }

                let label_status = label_map[order.portal_status]
                if (!label_status) label_status = 'info'
                return (
                    <label className={`label ${label_status}`}>
                        {$t(`orders.portal_status.${order.portal_status}`)}
                    </label>
                )
            }
        },
    }, {
        name: () => $t('filters.sort.types.created_on'),
        render: (row) => format_iso_to_date(row.created_on, $s.language),
    }, {
        name: () => $t('filters.sort.types.created_by'),
        render: (row) => {
            if (row.contact_person_name) {
                return row.contact_person_name
            }
            return config.account_name
        },
    }, {
        name: () => $t('filters.sort.types.number_of_cases'),
        render: (record) => {
            return <CellBottleAndCase
                bottle_text={record.number_of_bottles ? `${record.number_of_bottles} btl` : ''}
                case_text={record.number_of_bottles ? `${record.number_of_cases} cs` : ''}
            />
        },
    }, {
        name: () => $t('filters.sort.types.total_value'),
        render: (row) => format_money_with_symbol(row.total_value, row.currency),
    }, {
        name: () => $t('filters.sort.types.invoice_number'),
        render: (row) => row.invoice_number ? row.invoice_number : '-',
    }, {
        name: () => $t('filters.sort.types.is_invoiced_on'),
        render: (row) => row.is_invoiced_on ? format_iso_to_date(row.is_invoiced_on, $s.language) : '-',
    },
]

export class OrderList extends MithrilTsxComponent<any> {

    oninit() {
        Object.assign($s.page, {
            icon: 'myOrders',
            title: $t('page.my_orders'),
        })
        collection.init({
            endpoint: {
                method: 'get',
                path: 'portal/orders/collection-view',
            },
            sort: {
                by: 'created_on',
                options: [
                    ['created_on', 'Created on'],
                    ['portal_status', 'Portal status'],
                    ['is_invoiced_on', 'Invoiced on'],
                ],
                order: 'desc',
            },
        }, filters, transforms)
    }

    view(_vnode:m.Vnode<any>) {
        return (
            <div className="c-order-list view-container">
                <CollectionView>
                    <CollectionHeader
                        collection={collection}
                        columns={columns}
                    />
                    <CollectionItems
                        collection={collection}
                        columns={columns}
                        context={this}
                        on_row_click={(row) => {
                            m.route.set(`/orders/${row.artkey}`)
                        }}
                    />
                </CollectionView>
            </div>
        )
    }
}
