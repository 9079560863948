import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {Button} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {$t, api, notifier, store} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {TokenItems} from '@/components/promotion'
import {CarouselGiveAway} from '@/components/promotion/carousel_giveaway'
import {ProgressPromotion} from '@/components/incentives/promotion/progress'

export class MyPortalPromotions extends MithrilTsxComponent<any> {
    data = proxy({
        locked_gift_placeholder: '',
        gift_slide: 0, // controlled by carousel and gift select (single-order only)
    })

    async oncreate() {
        await $m.order.get_active_promotion()
        if ($s.promotion.type === 'single-order') {
            const gift_slide_index = $s.promotion.gifts.findIndex((option) => option.id === $s.promotion.current)
            if (gift_slide_index >= 0) {
                this.data.gift_slide = gift_slide_index
            } else {
                this.data.gift_slide = 0
            }
        }
    }

    view(_vn:m.Vnode<any>) {
        const text_active_grace = $t('promotions.active_grace', {date: dayjs($s.promotion.grace_date).format('ll')})
        const text_active = $t('promotions.active', {
            date: `${dayjs($s.promotion.start_date).format('ll')} - ${dayjs($s.promotion.end_date).format('ll')}`,
        })
        if (!$s.promotion.active_grace || $s.promotion.loading) return
        return (
            <div className="c-promotions">
                <div className="content">
                    <div className="header">
                        <div className="title">
                            {$s.promotion.slogan}
                        </div>
                        <div className={classes('subtitle mt-1', {
                            'grace-warning': $s.promotion.active_grace && !$s.promotion.active,
                        })}>
                            {($s.promotion.active_grace && !$s.promotion.active) ? text_active_grace : text_active}
                        </div>
                    </div>

                    <div className="description">
                        <ProgressPromotion extended={true} />
                        {$t('promotions.description', {
                            currency: $s.identity.user.currency,
                            token_value: format_money_responsive($s.promotion.token_value, undefined, $s.identity.user.currency),
                        })}
                    </div>
                    {($s.promotion.active_grace && $s.promotion.type === 'multi-order') && <div className="promotion-stats">
                        <TokenItems
                            current_item={[$s.promotion, 'current']}
                            disabled={!$s.promotion.unlocked}
                            model={$s.promotion.lookup}
                            onafterupdate={() => {
                                // After changing the selection, we need to recalculate the promotion status.
                                store.save()
                                $m.order.active_promotion_status()
                            }}
                            onchange={(option) => {
                                const gift_slide_index = $s.promotion.gifts.findIndex((gift) => gift.id === option.id)
                                if (gift_slide_index >= 0) {
                                    this.data.gift_slide = gift_slide_index
                                } else {
                                    this.data.gift_slide = 0
                                }
                            }}
                            onsubmit={async() => {
                                const orders = Object.entries($s.promotion.lookup).map(([promotion_item_id, quantity]) => ({
                                    quantity,
                                    promotion_item_id,
                                })).filter((item) => item.quantity > 0)

                                const {result, status_code}:{result: any; status_code:number} = await api.post(`portal/promotions/${$s.promotion.artkey}/order`, {orders}, true)
                                if (status_code !== 201) {
                                    throw new Error('invalid promotion details')
                                }

                                notifier.notify($t('notifications.promotion_redeemed'), 'success', undefined, 'promotion')
                                m.route.set(`/orders/${result.artkey}?confirm_order=1&is_promotion=1`)

                                // Remove the local selection and update the store
                                for (const key of Object.keys($s.promotion.lookup)) {
                                    delete $s.promotion.lookup[key]
                                }
                                store.save()
                                // (!) Keep the current order; promotion selection depends on the code above.
                                await $m.order.get_active_promotion()
                            }}
                            options={$s.promotion.gifts.map((gift:any) => ({
                                description: gift.description,
                                id: gift.id,
                                title: gift.title,
                                tokens_required: gift.tokens_required,
                            }))}
                            placeholder={$s.promotion.unlocked ? $t('promotions.gifts.unlocked_placeholder') : $t('promotions.gifts.locked_placeholder')}
                        />
                    </div>}
                </div>
                <div className="carousel-wrapper">
                    {!$s.promotion.terms.collapsed && <div className="terms">
                        <Button
                            className="btn-close"
                            icon="close"
                            onclick={() => {
                                $s.promotion.terms.collapsed = !$s.promotion.terms.collapsed
                            }}
                            size="s"
                            type={'default'}
                            variant="toggle"
                        />
                        <div className="content">
                            <div className="title">{$s.promotion.name}</div>
                            <div className="subtitle">
                                {$t('promotions.terms.conditions')}
                            </div>
                            <ul>
                                <li>{text_active}</li>
                                <li>{text_active_grace}</li>
                                <li> {$t('promotions.terms.terms_0')}</li>
                                <li> {$t('promotions.terms.terms_1')}</li>
                                <li> {$t('promotions.terms.terms_2')}</li>
                                <li> {$t('promotions.terms.terms_3')}</li>
                                <li> {$t('promotions.terms.terms_4')}</li>
                                <li> {$t('promotions.terms.terms_5')}</li>
                                <li> {$t('promotions.terms.terms_6')}</li>
                                <li> {$t('promotions.terms.terms_7')}</li>
                            </ul>
                        </div>
                    </div>}
                    <CarouselGiveAway
                        controls={false}
                        current={(() => this.data.gift_slide)()}
                        indicators={false}
                        onchange={(modelValue: number) => {
                            this.data.gift_slide = modelValue
                            // When the gift is unlocked, the slide and selection are linked together.
                            if ($s.promotion.unlocked) {
                                $s.promotion.current = $s.promotion.gifts[modelValue].id
                                store.save()
                            }
                        }}
                        slides={$s.promotion.gifts.map((option, i) => {
                            return {
                                description: option.description,
                                link: option.link,
                                title: option.title,
                                url: `/img/promotions/nl-action-${i}.jpg`,
                            }
                        })}
                    />
                </div>
            </div>
        )
    }
}
