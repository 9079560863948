import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldCheckbox} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {ProductInfo} from './info/info'
import {ProductSpecs} from './specs/specs'
import {ProductImage} from './image/image'

import {$s} from '@/app'

/**
 * This is an implementation of a Collection item, which has a 'grid'
 * and a 'list' mode.
 */
export class CollectionProduct extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        const collection = vnode.attrs.collection
        const offer_item = vnode.attrs.offer_item
        if (!offer_item) return

        return <div className={classes('c-collection-product', {
            'has-cart-actions': vnode.attrs.cart_actions,
        })}>
            {collection && $s.offers.export.list.active &&
            <FieldCheckbox
                className="select-toggle"
                onAfterChange={(newValue) => {
                    if (!newValue) {
                        collection.item_select(offer_item)
                    } else {
                        collection.item_deselect(offer_item)
                    }
                }}
                computed={() => {
                    if (collection.state.selection.all) {
                        return !collection.state.selection.ids.includes(offer_item.artkey)
                    } else {
                        return collection.state.selection.all || (collection.state.selection.ids.includes(offer_item.artkey))
                    }
                }}
            />}

            <ProductImage
                offer_item={offer_item}
                onclick={() => {
                    // Not all products (e.g. TBO) have photos; don't open the carousel in that case.
                    if (offer_item.artkey in $s.product_photos && $s.product_photos[offer_item.artkey].length) {
                        $s.carousel.selection = offer_item.artkey
                    }
                }}
            />

            <ProductSpecs offer_item={offer_item} />
            <ProductInfo
                offer_item={offer_item}
                variant={vnode.attrs.variant}
            />
            {!!vnode.attrs.cart_actions && vnode.attrs.cart_actions()}
        </div>
    }
}
