import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {$s} from '@/app'
import {ArrivingSoonWidget, NewInStockWidget, SpecialOffersWidget} from '@/components/dashboard/products'
import {SpotlightWidget} from '@/components/dashboard/spotlight'
import {TipsWidget} from '@/components/dashboard/tips'
import {DashboardOffersWidget} from '@/components/dashboard/offers/offers'
import {DialogPromotion} from '@/components/promotion/dialog_promotion'
import {LatestOrdersWidget} from '@/components/dashboard/latest_orders'
import {filters} from '@/components/pages/offers/offer_list'

export class Dashboard extends MithrilTsxComponent<any> {

    scrollContainer:HTMLElement

    async oninit() {
        // Reset category-menu selection
        filters.category.selection.splice(0)

        $s.page.icon = 'dashboard'
        $s.page.title = 'Dashboard'
        $s.page.header.collapsed = false
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className="c-dashboard view view-fw">
                <SpotlightWidget />

                {$s.promotion.active && !dayjs().isSame(dayjs($s.dialog.promotion), 'day') &&
                    <DialogPromotion/>
                }

                <div className="l-widget-row row-1">
                    <SpecialOffersWidget />
                    <NewInStockWidget />
                    <ArrivingSoonWidget />
                </div>

                <div className="l-widget-row row-2">
                    <DashboardOffersWidget/>

                    <LatestOrdersWidget/>
                    <div className="l-widget-column">
                        <TipsWidget/>
                    </div>
                </div>
            </div>
        )
    }
}
