import {
    AmountUnit,
    Button,
    CellBottleAndCase,
    CollectionColumns,
    CollectionItems,
    CollectionView,
    Icon,
    Link,
} from '@bitstillery/common/components'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {format_delivery_period, format_money_with_symbol} from '@bitstillery/common/lib/format'
import {classes} from '@bitstillery/common/lib/utils'
import {$t} from '@bitstillery/common/app'
import {CollectionProxy} from '@bitstillery/common/lib/collection'

import {OrderType} from './proforma'

import {
    SalesOrderAdditionalResponse,
    SalesOrderCreditItemResponse,
    SalesOrderItemResponse,
} from '@/factserver_api/fact2server_api'

const collection_cart = new CollectionProxy()
const collection_stock = new CollectionProxy()
const collection_arriving = new CollectionProxy()
const collection_tbo = new CollectionProxy()
const collection_credit = new CollectionProxy()
const collection_additionals = new CollectionProxy()

export class Details extends MithrilTsxComponent<any> {

    render_table(collection: CollectionProxy, order: OrderType, items, is_cart = false) {
        const columns = [
            {
                name: $t('order.details.product'),
                render: (row) => {
                    return (
                        <div className="td-group">
                            <span className="header">{row.product_name}</span>
                            <span className="details">{to_specs(row)}</span>
                            <span className="details">{row.case_article_code}</span>
                        </div>
                    )
                },
            }, {
                name: $t('order.details.status'),
                render: (row) => {
                    if (row.expected_delivery_date) {
                        const period = dayjs(row.expected_delivery_date).diff(dayjs(), 'week') + 1
                        return $t('filters.types.availability.eta', {period})
                    } else if (row.delivery_period) {
                        const weeks = Number(row.delivery_period)
                        if (isNaN(weeks)) {
                            return '-'
                        }
                        const period = format_delivery_period(row.delivery_period)
                        return $t('filters.types.availability.eta', {period})
                    } else if (row.offer_item_type === 'stock') {
                        return $t('filters.types.availability.stock')
                    } else {
                        return $t('filters.types.availability.tbo')
                    }
                },
            }, {
                name: $t('order.details.price'),
                render: (row) => {
                    const price = is_cart ? Number(row.list_price) : Number(row.price_per_case)
                    return <AmountUnit
                        case_amount={price}
                        case_number_of_bottles={row.case_number_of_bottles}
                        currency={order.sales_order.was_sold_in}
                        display_currency={order.sales_order.was_sold_in}
                    />
                },
            }, {
                name: $t('order.details.quantity'),
                render: (record) => {
                    return <CellBottleAndCase
                        bottle_text={
                            record.number_of_cases ? `${record.number_of_cases * record.number_of_bottles} btl` : ''
                        }
                        case_text={record.number_of_cases ? `${record.number_of_cases} cs` : ''}
                    />
                },
            }, {
                name: $t('order.details.subtotal'),
                render: (row) => {
                    const price = is_cart ? Number(row.list_price) : Number(row.price_per_case)
                    const value = row.number_of_cases * price
                    return (
                        <div className="subtotal">
                            {row.portal_comment && <Icon
                                name="comment"
                                size="s"
                                tip={row.portal_comment}
                                type="info"
                            />}
                            {format_money_with_symbol(value, order.sales_order.was_sold_in)}
                        </div>
                    )
                },
            },
        ]

        collection.state.items.splice(0, collection.state.items.length, ...items)
        return <CollectionView mode="table" view={false}>
            <CollectionColumns columns={columns}/>
            <CollectionItems
                collection={collection}
                columns={columns}
                type="table"
            />
        </CollectionView>
    }

    soi_group(items, title, icon, type) {
        if (!items) return
        return <div className={classes('soi-group', type)}>
            <div className="title">
                <Icon
                    name={icon}
                    tip={$t('order.details.booked_context')}
                />
                {title}
            </div>
            {items}
        </div>
    }

    view_additionals(order: OrderType) {
        const items: SalesOrderAdditionalResponse[] = order.sales_order_additionals

        if (items.length === 0) {
            return null
        }

        const columns = [
            {
                name: $t('order.details.additional.type'),
                render: (row: SalesOrderAdditionalResponse) => {
                    return (
                        <div className="td-group">
                            <span
                                className="header">{$t(`sales_order_addition_types.${row.sales_order_additional_type}`)}</span>
                            <span className="details">{row.description}</span>
                        </div>
                    )
                },
            }, {
                name: $t('order.details.additional.price_unit'),
                render: (row) => format_money_with_symbol(row.price_per_unit, order.sales_order.was_sold_in),
            }, {
                field: 'quantity',
                name: $t('order.details.additional.quantity'),
                render: (row) => row.quantity,
            }, {
                render: (row) => {
                    return format_money_with_symbol(row.total, order.sales_order.was_sold_in)
                },
                name: $t('order.details.subtotal'),
            },
        ]
        collection_additionals.state.items.splice(0, collection_additionals.state.items.length, ...items)
        return <CollectionView mode="table" view={false}>
            <CollectionColumns columns={columns}/>
            <CollectionItems
                collection={collection_additionals}
                columns={columns}
            />
        </CollectionView>
    }

    view_booked(order: OrderType) {
        if (order.sales_order_items.length + order.sales_order_tbo_items.length === 0) {
            return null
        }

        let stock_items: SalesOrderItemResponse[] = []
        let purchase_items: SalesOrderItemResponse[] = []

        for (const item of order.sales_order_items) {
            if (item.offer_item_type === 'purchase') {
                purchase_items.push(item)
            } else {
                stock_items.push(item)
            }
        }

        stock_items = stock_items.sort((a, b) => {
            const _a = a.product_name.toUpperCase()
            const _b = b.product_name.toUpperCase()
            if (_a < _b) return -1
            if (_a > _b) return 1
            return 0
        })

        const tbo_items = order.sales_order_tbo_items.sort((a, b) => {
            const _a = a.product_name.toUpperCase()
            const _b = b.product_name.toUpperCase()
            if (_a < _b) return -1
            if (_a > _b) return 1
            return 0
        })

        purchase_items = purchase_items.sort((a, b) => {
            const _a = a.product_name.toUpperCase()
            const _b = b.product_name.toUpperCase()
            if (_a < _b) return -1
            if (_a > _b) return 1
            return 0
        })

        return <div className="c-table-container">
            {stock_items.length > 0 && [
                (<div className="collection-subtitle">{$t('order.details.items.stock')}</div>),
                this.render_table(collection_stock, order, stock_items),
            ]}

            {purchase_items.length > 0 && [
                (<div className="collection-subtitle">{$t('order.details.items.arriving')}</div>),
                this.render_table(collection_arriving, order, purchase_items),
            ]}

            {tbo_items.length > 0 && [
                (<div className="collection-subtitle">{$t('order.details.items.tbo')}</div>),
                this.render_table(collection_tbo, order, tbo_items),
            ]}
        </div>
    }

    view_cart(order) {
        if (['Pending', 'Finalized'].indexOf(order.sales_order.portal_status) === -1) {
            return null
        }
        const cart_items = order.portal_order_items
        if (cart_items.length === 0) {
            return null
        }
        return <div className="c-table-container">
            {this.render_table(collection_cart, order, cart_items, true)}
        </div>
    }

    view_credit(order: OrderType) {
        const items: SalesOrderCreditItemResponse[] = order.sales_order_credit_items
        if (items.length === 0) {
            return null
        }

        const columns = [
            {
                classes: ['column-product'],
                render: (row: SalesOrderCreditItemResponse) => {
                    return (
                        <div className="td-group">
                            <span className="header">{row.product_name}</span>
                            <span className="details">{to_specs(row)}</span>
                            <span className="details">{row.case_article_code}</span>
                        </div>
                    )
                },
                name: $t('order.details.product'),
            }, {
                name: $t('order.details.credit.credited_from'),
                render: (row: SalesOrderCreditItemResponse) => {
                    return <Link
                        href={`/orders/${row.original_sales_order_artkey}`}>{`S${row.original_sales_order_artkey}`}</Link>
                },
            },
            {
                name: $t('order.details.price'),
                render: (row: SalesOrderCreditItemResponse) => {
                    return <AmountUnit
                        case_amount={row.original_price_per_case * -1}
                        case_number_of_bottles={row.case_number_of_bottles}
                        currency={order.sales_order.was_sold_in}
                        display_currency={order.sales_order.was_sold_in}
                    />
                },
            },
            {
                name: $t('order.details.quantity'),
                render: (row) => {
                    const case_quantity = row.number_of_cases * -1
                    const bottle_quantity = case_quantity * row.case_number_of_bottles
                    return `${case_quantity} (${bottle_quantity})`

                },
            },
            {
                name: $t('order.details.subtotal'),
                render: (row: SalesOrderCreditItemResponse) => {
                    return format_money_with_symbol(
                        row.number_of_cases * +row.original_price_per_case * -1,
                        order.sales_order.was_sold_in,
                    )
                },
            },
        ]

        collection_credit.state.items.splice(0, collection_credit.state.items.length, ...items)
        return <CollectionView mode="table" view={false}>
            <CollectionColumns columns={columns}/>
            <CollectionItems
                collection={collection_credit}
                columns={columns}
            />

        </CollectionView>
    }

    view(vnode: m.Vnode<any>) {
        const order = vnode.attrs.order
        const cart_items = order.portal_order_items
        const order_is_empty = [
            order.sales_order_items.length,
            order.sales_order_tbo_items.length,
            order.sales_order_additionals.length,
            order.sales_order_credit_items.length,
            cart_items.length,
        ]

        return <div className="c-order-details">
            {order_is_empty.every((i) => !i) && <div className="alert alert-info order-alert mt-2">
                <Icon className="icon-xxl" name="cart" type="unset"/>
                <div className="order-text">
                    <div className="title">{$t('order.details.empty')}</div>
                    <div className="description">
                        {$t('order.details.empty_context')}
                        <Button
                            className='mt-2'
                            icon='cartRight'
                            link='/offers'
                            text={$t('order.empty_call_to_action')}
                            type='info'
                        />
                    </div>
                </div>
            </div>}

            {this.soi_group(this.view_cart(order), $t('order.details.cart'), 'cartCheck', 'success')}
            {this.soi_group(this.view_booked(order), $t('order.details.booked'), 'cartRight', 'info')}
            {this.soi_group(this.view_additionals(order), $t('order.details.additionals'), 'offer', 'info')}
            {this.soi_group(this.view_credit(order), $t('order.details.credit.label'), 'cartUp', 'warning')}
        </div>
    }
}
