import m from 'mithril'
import {Icon, Progress} from '@bitstillery/common/components'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'

export class ProgressPromotion extends MithrilTsxComponent<any> {

    label(next_value: number, progress: number) {
        const token_value = $s.promotion.token_value
        let incentive = ''
        if (progress < 0.5) {
            incentive += $t('promotions.giveaway', {
                currency: $s.identity.user.currency,
                spent_next: format_money_responsive(token_value - next_value),
            })
        } else {
            incentive += $t('promotions.giveaway_encourage', {
                currency: $s.identity.user.currency,
                spent_next: format_money_responsive(token_value - next_value),
            })
        }

        return incentive
    }

    view(vn: m.Vnode<any>) {
        const token_value = $s.promotion.token_value
        const next_value = ($s.promotion.turnover_draft % token_value)
        const progress = next_value / token_value

        return <div className={classes('c-incentives-progress', vn.attrs.className)}>
            {<Progress
                boundaries={[
                    `${format_money_responsive(next_value, false, $s.identity.user.currency)}`,
                    `${format_money_responsive(token_value)} ${$s.identity.user.currency}`,
                ]}
                label={this.label(next_value, progress)}
                percentage={progress * 100}
                tip={() => $t('promotions.incentive_progress', {amount: format_money_responsive(token_value - next_value, false, $s.identity.user.currency)})}
            />}

            {$s.promotion.type === 'single-order' && <div className={classes('completed', {
                extended: vn.attrs.extended,
            })}>
                {vn.attrs.extended && <Icon
                    className="mr-1"
                    size="l"
                    name={$s.promotion.current ? 'thumbUp' : 'promotion'}
                    tip={() => {
                        return $t('promotions.eligible_multiple', {
                            turnover_draft: format_money_responsive($s.promotion.turnover_draft, undefined, $s.identity.user.currency),
                            turnover_redeemable: format_money_responsive($s.promotion.turnover_redeemable, undefined, $s.identity.user.currency),
                            token_value: format_money_responsive($s.promotion.token_value, undefined, $s.identity.user.currency),
                        })
                    }}
                />}
                <div className="completed-text">
                    {(() => {
                        if ($s.promotion.unlocked) {
                            if ($s.promotion.current) {
                                const choice = $s.promotion.gifts.find((option) => option.id === $s.promotion.current).description
                                return $t('promotions.gifts.unlocked_choice_made', {choice})
                            } else {
                                if (m.route.get() === '/my-portal/promotions') {
                                    return $t('promotions.gifts.unlocked_choose')
                                } else {
                                    return m.trust($t('promotions.gifts.unlocked_redirect', {link: '/#!/my-portal/promotions'}))
                                }
                            }
                        } else {
                            return $t('promotions.gifts.locked')
                        }
                    })()}
                </div>
            </div>}
        </div>

    }
}
