import m from 'mithril'
import {Changed, OfferItemAmount} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$m, $t} from '@bitstillery/common/app'

import {ProductAvailability} from '../availability/availability'
import {ProductQuantity} from '../quantity/quantity'

import {$s} from '@/app'

/**
 * Represents detailed product information, incorporating dynamic pricing, availability,
 * and cart management features within an eCommerce or retail setting. It handles different
 * viewing modes and variants such as offer and shopping cart views, manages display of
 * price changes and excise details, and shows product availability and quantity management
 * options based on the supply situation and cart content.
 *
 * @extends {MithrilTsxComponent<any>}
 */
export class ProductInfo extends MithrilTsxComponent<any> {

    quantity_context: any

    view(vn: m.Vnode<any>) {
        const offer_item = vn.attrs.offer_item
        const offer_item_price = $m.offer.offer_item_price(offer_item)
        const cart_item = $s.cart.items[offer_item.case_artkey]

        return (
            <div className={classes('c-product-info', `variant-${vn.attrs.variant}`)}>
                <div className="pricing">
                    {(() => {
                        const is_discount = $m.offer.DISCOUNT_TYPES.includes(offer_item.offer_type)
                        if (vn.attrs.variant === 'offer') {
                            // Only special offers are shown with a from/to price; lowered prices from
                            // regular items are not explicitly shown to the customer.
                            return (is_discount && offer_item_price.is_down) ? <Changed
                                currency={offer_item.currency}
                                current={offer_item_price.current}
                                previous={offer_item_price.previous}
                                validity={offer_item.validity_date}
                            /> : <OfferItemAmount
                                currency={offer_item.currency}
                                offer_item={offer_item}
                                value={offer_item_price.current}
                            />
                        } else if (vn.attrs.variant === 'cart') {
                            // Shows a sub-total, instead of a unit price.
                            const cart_amount = $m.offer.unit_amount(cart_item.number_of_cases, offer_item.case_number_of_bottles)
                            const total = <OfferItemAmount
                                currency={offer_item.currency}
                                offer_item={offer_item}
                                value={cart_amount * offer_item_price.current}
                            />
                            const changed_total = <Changed
                                currency={offer_item.currency}
                                current={cart_amount * offer_item_price.current}
                                previous={cart_amount * offer_item_price.previous}
                                type={offer_item_price.is_invalid ? 'warning' : 'brand'}
                                validity={offer_item.validity_date}
                            />

                            return ((is_discount && offer_item_price.is_down) || offer_item_price.is_invalid) ? changed_total : total
                        }
                    })()}
                    {($s.identity.user.show_excise && offer_item_price.excise > 0.01) && <div className='excise'>
                        <span>{$s.identity.user.includes_excise ?
                            $t('excise.including', {amount: format_money_responsive(offer_item_price.excise, false, offer_item.currency)}) :
                            $t('excise.excluding', {amount: format_money_responsive(offer_item_price.excise, false, offer_item.currency)})}
                        </span>
                    </div>}
                </div>

                <div className="mode-wrapper">
                    <ProductQuantity
                        context={[this, 'quantity_context']}
                        offer_item={offer_item}
                    />

                    {this.quantity_context && <ProductAvailability
                        context={this.quantity_context}
                        offer_item={offer_item}
                    />}
                </div>
            </div>
        )
    }
}
