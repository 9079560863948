import {ModelsCommon} from '@bitstillery/common/models'

import * as cart from './cart'
import * as activity from './activity'
import * as filter from './filter'
import * as identity from './identity'
import * as order from './order'

export interface ModelsPortal extends ModelsCommon {
    activity: typeof activity
    cart: typeof cart
    filter: typeof filter
    identity: typeof identity
    order: typeof order
}

export const models = {
    activity,
    cart,
    filter,
    identity,
    order,
}
