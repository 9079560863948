import {
    volatile as volatile_common,
    StateCommon,
    persistent as persistent_common,
    session as session_common,
} from '@bitstillery/common/lib/state'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'

import {
    AlcoholFormatting,
    CountriesOfOriginResponse,
    GetPortalProfileResponse,
    VolumeFormatting,
} from '@/factserver_api/fact2server_api'

export interface StatePortal extends StateCommon {
    cart: {
        // This is a shared model for ProductQuantity amounts
        artkey: null | string
        carousel: {
            selection: null | number
        }
        delivery: {
            date: string | null
            options: [
                ['asap', 'checkout.delivery.asap'],
                ['date', 'checkout.delivery.date']
            ]
            selection: 'asap' | 'date'
        }
        errors: {
            api: {}
            price_up: {}
            unavailable: {}
            less_available: {}
            minimum_quantity: {}
        }
        finalize: {
            options: [
                [false, 'checkout.status.book'],
                [true, 'checkout.status.finalize'],
            ]
            selection: boolean
        }
        includes_excise: false
        items: {} // Map an offer_item by artkey to a portal_order_item
        item_changed: null | string
        loading: boolean
        offer_items: {} // A mapping of active orderItems to related offer_items
        sales_order_additionals: []
        sales_order_credit_items: []
        sales_order_items: []
        sales_order_tbo_items: []
        show_bottles: boolean
        unavailable: []
        vouchers: {
            active: boolean
            available: []
        }
    }
    categories: {
        lookup: {}
        menu: []
    }
    countries_of_origin: {
        [key: string]: CountriesOfOriginResponse[]
    }
    dialog: {
        offer: null | string
        promotion: null
    }
    identity: {
        artkey: string
        exp: null | string
        token: null | string
        user: GetPortalProfileResponse
    }
    offers: {
        export: {
            list: {
                active: boolean
                formatting: {
                    alcohol: {
                        selection: AlcoholFormatting
                    }
                    size: {
                        selection: VolumeFormatting
                    }
                }
                loading: boolean
                toggle: boolean
            }
            bar: {
                active: boolean
                formatting: {
                    alcohol: {
                        selection: AlcoholFormatting
                    }
                    size: {
                        selection: VolumeFormatting
                    }
                }
                loading: boolean
                toggle: boolean
            }
        }
        personal: [{
            end_date: string
            hash: string
            title: string
        }]
        records: []
        special: number
        new_arrivals: number
    }
    order: {
        empty: []
        pending: []
        sort: {
            by: 'created_on'
            order: 'desc'
        }
    }
    product_photos: {}
    promotion: {
        active: boolean
        active_grace: boolean
        /** Identifier for the promotion API */
        artkey: number
        current: string
        end_date: string
        grace_date: string
        gifts: {
            description: string
            link: string
            title: string
            slug: string
            url: string
        }[]
        icon: string
        /** Simple local persistance for gift slug and selected amount */
        loading: boolean
        lookup: Record<string, number>
        name: string
        slogan: string
        slug: string
        start_date: null | string
        terms: {
            collapsed: boolean
        }
        type: null | string
        /** Spent amount of money on sales orders during the promotion */
        turnover_draft: number
        turnover_redeemable: number
        token_icon: string
        /** Amount of money that represents one token */
        token_value: number
        tokens_draft: number
        tokens_redeemable: number
        /** Selected amount of tokens for redeeming */
        tokens_selected: number
        /** Determines whether a promotion can be redeemed */
        unlocked: boolean
    }
    portal: {
        account: {}
        ready: boolean
    }
    suggestions: {
        selected: {
            index: null | number
            type: string
        }
        types: {
            category: {
                name: string
                options: []
            }
            search: {
                name: string
                options: []
            }
        }
    }
}

export const volatile = merge_deep({
    cart: {
        artkey: null,
        delivery: {
            date: null,
            options: [
                ['asap', 'checkout.delivery.asap'],
                ['date', 'checkout.delivery.date'],
            ],
            selection: 'asap',
        },
        errors: {
            api: {},
            price_up: {},
            unavailable: {},
            less_available: {},
            minimum_quantity: {},
        },
        finalize: {
            options: [
                [false, 'checkout.status.book'],
                [true, 'checkout.status.finalize'],
            ],
            selection: true,
        },
        items: {},
        item_changed: null,
        loading: true,
        offer_items: {},
        sales_order_additionals: [],
        sales_order_credit_items: [],
        sales_order_items: [],
        sales_order_tbo_items: [],
        unavailable: [],
        vouchers: {
            active: false,
            available: [],
        },
    },
    carousel: {
        selection: null,
    },
    countries_of_origin: {},
    currencies: {
        default: 'EUR',
        all: ['EUR', 'GBP', 'USD', 'JPY'],
    },
    identity: {
        artkey: null,
        exp: null,
        user: {
            artkey: null,
            username: '',
            email_address: '',
            language: '',
            first_name: '',
            last_name: '',
            name: '',
            phone_number: '',
            mobile_phone_number: '',
            tawk_hash: '',
            country_code: '',
            currency: '',
            minimum_order_amount: '',
            price_preference: 'case',
            include_excise_in_price: true,
            show_excise: false,
            relation_name: '',
            customs_visibility: '',
            sales_manager: {
                artkey: null,
                name: '',
                email_address: '',
                phone_number: '',
            },
        },
    },
    offers: {
        export: {
            bar: {
                active: false,
                loading: false,
                toggle: false,
            },
            list: {
                active: false,
                loading: false,
                toggle: false,
            },
        },
        personal: [],
        records: [],
        special: 0,
    },
    order: {
        empty: [],
        pending: [],
        sort: {
            by: 'created_on',
            order: 'desc',
        },
    },
    page: {
        breadcrumbs: [],
        header: {
            collapsed: false,
        },
        help: null,
        icon: '',
        subtitle: '',
        title: '',
    },
    product_photos: {},
    promotion: {
        active: false,
        active_grace: false,
        end_date: null,
        gifts: [],
        icon: 'bean',
        loading: true,
        name: '',
        slogan: '',
        start_date: null,
        terms: {
            collapsed: true,
        },
        token_icon: '',
        token_value: 0,
        tokens_draft: 0,
        tokens_selected: 0,
        turnover_draft: 0,
        turnover_redeemable: 0,
        type: null,
    },
    suggestions: {
        selected: {
            index: null,
            type: 'product',
        },
        // These types map directly to filters
        types: {
            category: {
                name: 'filters.types.category',
                options: [],
            },
            search: {
                name: 'filters.types.products',
                options: [],
            },
        },
    },
}, copy_object(volatile_common))

export const session = merge_deep({}, copy_object(session_common))

export const persistent = merge_deep({
    cart: {
        show_bottles: false,
    },
    categories: {
        lookup: {},
        menu: [],
    },
    dialog: {
        offer: null,
        promotion: null,
    },
    identity: {
        token: null,
    },
    offers: {
        export: {
            bar: {
                formatting: {
                    alcohol: {
                        selection: 'percentage',
                    },
                    size: {
                        selection: 'cl',
                    },
                },
            },
            list: {
                formatting: {
                    alcohol: {
                        selection: 'percentage',
                    },
                    size: {
                        selection: 'cl',
                    },
                },
            },
        },
    },
    portal: {
        account: null,
        ready: false,
    },
    promotion: {
        lookup: {},
    },
    session: copy_object(session),
}, copy_object(persistent_common))
