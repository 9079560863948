import m from 'mithril'
import {Button, Changed, Icon, Spinner} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$t, events, notifier} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {filters} from '@/components/pages/offers/offer_list'

export class OrderItems extends MithrilTsxComponent<any> {

    error_tip(cart_item) {
        const errors = $s.cart.errors
        if (errors.api[cart_item.case_artkey]) {
            return `${$t('cart.error_api')} ${$t('cart.error_help.api')}`
        } else if (errors.unavailable[cart_item.case_artkey]) {
            return `${$t('cart.unavailable')} ${$t('cart.unavailable_action')}`
        } else if (errors.less_available[cart_item.case_artkey]) {
            const count = $m.offer.unit_amount(errors.less_available[cart_item.case_artkey], cart_item.case_number_of_bottles)
            return `${$t('cart.less_available', {
                count,
                units: $t(`unit.${$s.identity.user.price_preference}`, {count}),
            })} ${$t('cart.less_available_action')}`
        } else if (errors.price_up[cart_item.case_artkey]) {
            return $t('cart.price_changed', {
                old_price: $m.offer.unit_price(errors.price_up[cart_item.case_artkey].basket_price, cart_item.case_number_of_bottles),
                new_price: $m.offer.unit_price(errors.price_up[cart_item.case_artkey].list_price, cart_item.case_number_of_bottles),
                currency: $s.identity.user.currency,
            })
        } else if (errors.minimum_quantity[cart_item.case_artkey]) {
            // Todo; share order-quantity computed methods with model
            const count = $m.offer.unit_amount(errors.minimum_quantity[cart_item.case_artkey], cart_item.case_number_of_bottles)
            return `${$t('cart.min_quantity', {
                count,
                units: $t(`unit.${$s.identity.user.price_preference}`, {count}),
            })} ${$t('cart.min_quantity_action')}`
        }
    }

    find_product(product_name) {
        filters.offertype.selection = ''
        filters.search.selection.splice(0, filters.search.selection.length, product_name)
        events.emit('search', filters.search.selection)
    }

    /**
     * Common order line renderer. An order_item can be a sales_order_item
     * or a cart_item; e.g. an entry from $s.cart.items. Only the latter has
     * validation logic, because sales_order_items have already been booked.
     */
    order_line(order_item, is_cart) {
        const errors = $s.cart.errors
        const _classes = ['product-line'] as any
        if (is_cart) {
            const cart_item = order_item
            _classes.push({
                'cart-item-changed': cart_item.case_artkey === $s.cart.item_changed,
                error: errors.api[order_item.case_artkey],
                warning: (
                    errors.less_available[cart_item.case_artkey] ||
                    errors.unavailable[cart_item.case_artkey] ||
                    errors.price_up[cart_item.case_artkey] ||
                    errors.minimum_quantity[cart_item.case_artkey]
                ),
            })
        }

        return <div className={classes(_classes)}>
            {(() => {
                let name = $s.identity.user.price_preference
                let type = 'default'
                if (is_cart && (
                    errors.api[order_item.case_artkey] ||
                    errors.less_available[order_item.case_artkey] ||
                    errors.unavailable[order_item.case_artkey] ||
                    errors.price_up[order_item.case_artkey] ||
                    errors.minimum_quantity[order_item.case_artkey]
                )) {
                    const cart_item = order_item
                    name = 'info'
                    if (errors.api[cart_item.case_artkey]) type = 'danger'
                    else type = 'warning'
                } else if ($m.cart.data.stepper.selection === 1) {
                    name = 'checked'
                }

                return <Icon
                    className='quantity-type-icon'
                    name={name}
                    size="s"
                    tip={() => this.error_tip(order_item)}
                    type={type}
                />
            })()}
            <div className="quantity">{$m.offer.unit_amount(order_item.number_of_cases, order_item.case_number_of_bottles)}</div>

            <div className="product-name" onclick={() => {
                // Only search for related product when on the stock page:
                if ($m.cart.data.stepper.selection === 0) {
                    this.find_product(order_item.product_name)
                }
            }}>
                {order_item.product_name}
            </div>

            <div className="costs">
                {(() => {
                    let subtotal_price

                    if (is_cart) {
                        const cart_item = order_item
                        const offer_item = $s.cart.offer_items[cart_item.case_artkey]
                        if (!offer_item) return

                        const offer_item_price = $m.offer.offer_item_price(offer_item)
                        const unit_amount = $m.offer.unit_amount(cart_item.number_of_cases, cart_item.case_number_of_bottles)
                        subtotal_price = unit_amount * offer_item_price.current
                        if (errors.price_up[cart_item.case_artkey]) {
                            subtotal_price = cart_item.number_of_cases * Number(errors.price_up[cart_item.case_artkey].basket_price)
                            let new_price = cart_item.number_of_cases * Number(errors.price_up[cart_item.case_artkey].list_price)
                            return <Changed
                                previous={subtotal_price}
                                current={new_price}
                                type="warning"
                            />
                        }

                        return format_money_responsive(subtotal_price)
                    } else {
                        subtotal_price = order_item.number_of_cases * Number(order_item.price_per_case)
                    }

                    return format_money_responsive(subtotal_price)
                })()}
            </div>

            {(() => {
                if (!is_cart) return
                const cart_item = order_item

                return <div className="actions">
                    <Button
                        className="btn-remove"
                        icon='trash'
                        onclick={async() => {
                            for (const [, errors] of Object.entries($s.cart.errors)) {
                                if (errors[cart_item.case_artkey]) {
                                    delete errors[cart_item.case_artkey]
                                }
                            }

                            notifier.notify($t('notifications.removed_product', {product: order_item.product_name}), 'info', undefined, 'cartRemove')
                            await $m.cart.update_cart({
                                offer_item_artkey: cart_item.offer_item_artkey,
                                number_of_cases: 0,
                            }, cart_item.case_artkey)
                        }}
                        size="xs"
                        tip={$t('cart.minus_all', {product: cart_item.product_name})}
                        variant="unset"
                    />
                    {$s.cart.errors.less_available[cart_item.case_artkey] && <Button
                        className="btn-validate-quantity"
                        icon='checked'
                        onclick={async() => {
                            const number_of_cases = errors.less_available[cart_item.case_artkey]
                            await $m.cart.update_cart({
                                offer_item_artkey: cart_item.offer_item_artkey,
                                number_of_cases,
                            }, cart_item.case_artkey)
                            delete $s.cart.errors.less_available[cart_item.case_artkey]
                            notifier.notify($t('cart.new_quantity_confirmed'))
                        }}
                        size="xs"
                        tip={() => {
                            const current_units = $m.offer.unit_amount(cart_item.number_of_cases, cart_item.case_number_of_bottles)
                            const count = current_units - $s.cart.errors.less_available[cart_item.case_artkey]
                            return $t('cart.minus', {
                                count,
                                product: cart_item.product_name,
                                units: $t(`unit.${$s.identity.user.price_preference}`, {count}),
                            })
                        }}
                        variant="unset"
                    />}
                    {$s.cart.errors.minimum_quantity[cart_item.case_artkey] && <Button
                        className="btn-validate-quantity"
                        icon='checked'
                        onclick={async() => {
                            const number_of_cases = errors.minimum_quantity[cart_item.case_artkey]
                            await $m.cart.update_cart({
                                offer_item_artkey: cart_item.offer_item_artkey,
                                number_of_cases,
                            }, cart_item.case_artkey)
                            delete $s.cart.errors.minimum_quantity[cart_item.case_artkey]
                            notifier.notify($t('cart.new_quantity_confirmed'))
                        }}
                        size="xs"
                        tip={() => {
                            const count = $m.offer.unit_amount(errors.minimum_quantity[cart_item.case_artkey], cart_item.case_number_of_bottles)
                            return $t('cart.min_quantity_confirm', {
                                count,
                                units: $t(`unit.${$s.identity.user.price_preference}`, {count}),
                            })
                        }}
                        variant="unset"
                    />}
                </div>
            })()}

        </div>
    }

    view(vn:m.Vnode<any>) {
        const cart_items = Object.values($s.cart.items)
        return (
            <div className={classes('c-order-items', {
                [`step-${$m.cart.data.stepper.selection}`]: true,
            })}>
                {['all', 'cart'].includes(vn.attrs.filter) && [
                    <div className="break-line">
                        <Icon className="quantity-type-icon" name="cartRight" />
                        {$t('cart.soi.cart_title', {count: Object.keys($s.cart.items).length})}
                    </div>,
                    !cart_items.length ? <div className="empty-cart">
                        {(() => {
                            if ($s.cart.loading && !$s.cart.artkey) return <Spinner size="l" type="success" />
                            return [
                                <Button
                                    className="icon-order"
                                    icon="cartPlus"
                                    type="success"
                                    variant="toggle"
                                />,
                                $t('cart.empty_title'),
                            ]
                        })()}
                    </div> : null,
                    ...cart_items.sort((a, b) => a.product_name.localeCompare(b.product_name)).map((cart_item) => {
                        return this.order_line(cart_item, true)
                    }),
                ]}

                {['all', 'booked'].includes(vn.attrs.filter) && !!$s.cart.sales_order_items.length && [
                    <div className="break-line">
                        <Icon className="quantity-type-icon" name="booked" />
                        {$t('cart.soi.booked_title')}
                    </div>,
                    ...$s.cart.sales_order_items.map((sales_order_item) => this.order_line(sales_order_item, false)),
                ]}
            </div>
        )
    }
}
