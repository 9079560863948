import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy, type_remove_watch_function} from '@bitstillery/common/lib/proxy'
import {$t, events} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {filters} from '@/components/pages/offers/offer_list'

/**
 * A Search component that provides suggestions from
 * multiple sources. An issued search will always add
 * an extra filter selection, unless its a radio-group
 * type filter.
 */
export class SearchSite extends MithrilTsxComponent<any> {

    data = proxy({
        collapsed: true,
        placeholder: '',
    })

    matches = {
        category: [],
        indexes: [] as number[],
        search: [],
        suggestions: [],
    }

    product_names: (string | null)[][] = []
    search_categories: any[] = []
    watchers: type_remove_watch_function[] = []
    remove_on_click_outside: () => void

    async oninit() {
        [this.search_categories, this.product_names] = await Promise.all([
            $m.filter.get_search_categories(),
            $m.filter.get_product_names(),
        ])

        events.on('search.match-suggestions', this.match_suggestions.bind(this))
    }

    oncreate(vn) {
        const outside_click_listener = (event) => {
            if (!vn.dom.contains(event.target) && !this.data.collapsed) {
                this.data.collapsed = true
            }
        }

        document.addEventListener('click', outside_click_listener)
        this.remove_on_click_outside = () => {
            document.removeEventListener('click', outside_click_listener)
        }
    }

    onremove() {
        this.watchers?.map((unwatch) => unwatch())
    }

    apply_filter(use_suggestion = false) {
        if ($s.suggestions.selected.index === null && use_suggestion) {
            return
        }
        let search_data
        let filter_type = 'search'
        if (use_suggestion && $s.suggestions.selected.index !== null) {
            search_data = this.matches.suggestions[$s.suggestions.selected.index]
            filter_type = search_data[5]
        }
        else {
            if (!filters.search.input) return
            search_data = [filters.search.input]
        }

        const filter = filters[filter_type]
        if (filter_type === 'category') {
            let ids = [search_data[0]]
            // Also select the children if its a parent
            if (search_data[3]) {
                ids = [search_data[0], ...search_data[3].map((i) => i[0])]
            }

            let selection = filter.selection as number[]
            ids = ids.filter((id) => !selection.includes(id))
            if (ids.length) selection.push(...ids)
        } else if (filter_type === 'search') {
            if (!filter.selection.includes(filter.input)) {
                filter.selection.push(search_data[0])
            }
            events.emit('search', filters.search.selection)
        }

        // Default action after a search is to clear the input
        // and close the search widget.
        filters.search.input = ''
        this.data.collapsed = true

        // Navigate to the inventory page, with the filter state applied
        // in the url. The offer module will reconstruct the filter selection
        // from the url.
        if (!m.route.get().startsWith('/offer')) {
            // Not yet in the offer module; route to it using search parameters.
            if (filter_type === 'category') {
                m.route.set(get_route('/offers', {category: filters.category.selection}))
            } else {
                m.route.set(get_route('/offers', {
                    search: encodeURIComponent(filters.search.selection.join(',')),
                }))
            }
        } else {
            // Already at the offer module. Just trigger the search event
            // and let the search listener there take over.
            events.emit('search', filters.search.selection)
        }
    }

    context_tip() {
        if (this.data.collapsed) {
            return $t('search.placeholder.general')
        }

        if (filters.search.input === '') {
            return $t('search.placeholder.raw')
        }
        return $t('search.placeholder.raw_term', {term: filters.search.input})
    }

    format_suggestion(name, count) {
        if (count !== null) {
            // Assume case/bottle amount
            if (Array.isArray(count)) {
                return <span className="name">
                    {name} (${$s.cart.show_bottles ? count[1] : count[0]})
                </span>
            }
            return <span className="name">{name} ({count})</span>
        }
        return <span className="name">{name}</span>
    }

    match_suggestions() {
        const search_term = filters.search.input.toLowerCase()
        if (!search_term) {
            this.matches.search.splice(
                0, this.matches.search.length,
                ...this.product_names,
            )
            this.matches.category.splice(
                0, this.matches.category.length,
                ...this.search_categories,
            )
            this.update_matches()
            return
        }

        this.matches.category.splice(
            0, this.matches.category.length,
            ...this.search_categories.filter(([_, name]) => {
                return name.includes(search_term)
            }),
        )

        const _search = search_term.split(' ')
        this.matches.search = this.product_names.filter((i) => {
            return _search.every((j) => i[0].includes(j))
        })

        this.update_matches()
    }

    make_safe_index(new_index: number | null): number | null {
        if (new_index === null) {
            return null
        }
        const max = this.matches.suggestions.length
        if (new_index < 0 || new_index >= max) {
            return null
        }
        return new_index
    }

    navigate(keyboard_event: KeyboardEvent | 'reset') {
        const index_is_null = $s.suggestions.selected.index === null
        const index = $s.suggestions.selected.index || 0
        const pressed_key = typeof keyboard_event !== 'string' ? keyboard_event.key : ''

        if (pressed_key === 'Escape') { // reset search
            this.data.collapsed = true
            $s.suggestions.selected.index = null
            filters.search.input = ''
            this.apply_filter(false)
        }
        else if (pressed_key === 'ArrowDown' && index_is_null) {
            if (this.data.collapsed) {
                this.data.collapsed = false
            }
            $s.suggestions.selected.index = this.make_safe_index(0)
        }
        else if (pressed_key === 'ArrowDown') {
            if (this.data.collapsed) {
                this.data.collapsed = false
            }
            $s.suggestions.selected.index = this.make_safe_index(index + 1)
        }
        else if (pressed_key === 'PageDown' && index_is_null) {
            $s.suggestions.selected.index = this.make_safe_index(10)
        }
        else if (pressed_key === 'PageDown') {
            $s.suggestions.selected.index = this.make_safe_index(index + 10)
        }
        else if (pressed_key === 'ArrowUp' && index_is_null) {
            $s.suggestions.selected.index = this.make_safe_index(-1)
        }
        else if (pressed_key === 'ArrowUp') {
            $s.suggestions.selected.index = this.make_safe_index(index - 1)
        }
        else if (pressed_key === 'PageUp' && index_is_null) {
            $s.suggestions.selected.index = this.make_safe_index(-1)
        }
        else if (pressed_key === 'PageUp') {
            $s.suggestions.selected.index = this.make_safe_index(index - 10)
        }
        else if (pressed_key === 'ArrowRight' && index_is_null) {
            $s.suggestions.selected.index = this.make_safe_index(this.matches.indexes[0])
        }
        else if (pressed_key === 'ArrowRight') {
            if (index > this.matches.indexes[0]) { // already in right column,  noop
                return true
            }
            $s.suggestions.selected.index = this.make_safe_index(index + this.matches.indexes[0])
        }
        else if (pressed_key === 'ArrowLeft' && index_is_null) {
            $s.suggestions.selected.index = this.make_safe_index(0)
        }
        else if (pressed_key === 'ArrowLeft') {
            if (index < this.matches.indexes[0]) { // already in left column, noop
                return true
            }
            $s.suggestions.selected.index = this.make_safe_index(index - this.matches.indexes[0])
        }
        else {
            // Unhandled key pressed or reset
            if ($s.suggestions.selected.index !== null) {
                let search_input: HTMLInputElement | null = document.querySelector('#search-input')
                if (search_input) {
                    search_input.selectionEnd = 10000
                    search_input.selectionStart = 10000
                }
                $s.suggestions.selected.index = null // reset
                return true
            }
            $s.suggestions.selected.index = null // reset
            return false
        }

        let active_suggestion = document.querySelector(`#suggestion-${$s.suggestions.selected.index}`)
        if (!active_suggestion) {
            return true
        }

        const container_height = active_suggestion.parentElement?.clientHeight
        const item_height = active_suggestion.clientHeight
        // @ts-ignore
        let scroll_pos = active_suggestion.offsetTop

        if (container_height) {
            if (scroll_pos < container_height) {
                scroll_pos = 0
            } else {
                scroll_pos -= container_height - item_height
            }
        }

        active_suggestion.parentElement?.scrollTo({
            // Smooth scrolling while holding up
            behavior: 'smooth',
            left: 0,
            top: scroll_pos,
        })
        return true
    }

    oninput(e) {
        filters.search.input = e.target.value
        // After adding a search term, the search widget is closed.
        // However, the input is still focussed. Re-open the widget
        // when the user continues typing a next search term.
        if (this.data.collapsed) {
            this.data.collapsed = false
        }

        this.match_suggestions()
    }

    onkeydown(e: KeyboardEvent) {
        // The e.repeat is only true in Chrome; smooth scrolling in Chrome
        // doesn't work well when a lot of keydown events cause scrollTo
        // events.Instead, users must use pagedown and pageup keys for
        // faster scrolling there. Firefox skips this check.
        if (e.repeat) {
            return
        }

        if (e.key === 'Enter') {
            // Enter while the widget is hidden results in opening the widget.
            if (this.data.collapsed) {
                this.data.collapsed = false
            }
            else if ($s.suggestions.selected.index !== null) {
                this.apply_filter(true)
            }
            else {
                this.apply_filter(false)
            }
        } else {
            if (this.navigate(e)) {
                /* If dealt with the navigation, swallow the event. */
                e.stopPropagation()
                e.preventDefault()
            }
        }
    }

    /**
     * This adds a feature to the default copy-paste. Multiple tabs or
     * newlines in the text are splitted to multiple search terms. This
     * makes it easier to search multiple items from an excel sheet.
     * @param e - The copy-paste event
     */
    onpaste(e) {
        e.preventDefault()
        const data = e.clipboardData.getData('text')
        const cleaned_data = data.split(/[\n\t]+/).map((i) => i.trim()).filter((i) => i)
        if (cleaned_data.length === 1) {
            filters.search.input = cleaned_data[0]
        } else {
            // Only add terms that are not yet included.
            const filtered_terms = cleaned_data.filter((i: never) => !filters.search.selection.includes(i))
            if (filtered_terms.length) {
                filters.search.selection.push.apply(filters.search.selection, filtered_terms)
            }
        }
        this.match_suggestions()
    }

    suggestion_delegate(e, apply_filter = true) {
        if (!e.target.getAttribute('id')) return
        $s.suggestions.selected.index = parseInt(e.target.getAttribute('id').replace('suggestion-', ''))
        if (apply_filter) {
            this.apply_filter(true)
        }
    }

    update_matches() {
        this.matches.suggestions = [
            ...this.matches.category,
            ...this.matches.search,
        ]

        this.matches.indexes = [
            this.matches.category.length,
            this.matches.search.length,
        ]
    }

    view() {
        const suggestion_types = Object.entries(($s.suggestions.types))
        return (
            <div className={classes('c-search-site', {
                active: !this.data.collapsed,
            })}>
                <div className="search-box">
                    <input
                        autocomplete="off"
                        name="search"
                        id={'search-input'}
                        type="text"
                        value={filters.search.input}
                        onfocus={() => {
                            this.match_suggestions()
                            this.data.collapsed = false
                            this.navigate('reset')
                        }}
                        oninput={(e) => this.oninput(e)}
                        onkeydown={(e) => {this.onkeydown(e)}}
                        onpaste={(e) => this.onpaste(e)}
                        placeholder={(() => this.context_tip())()}
                    />

                    <Icon
                        className="btn-search"
                        disabled={!filters.search.input.length}
                        name="search"
                        onclick={() => {
                            // Search mode depends on ctrl-key being pressed
                            this.apply_filter(false)
                        }}
                        tip={() => {
                            return this.context_tip()
                        }}
                    />

                    <Icon
                        className="btn-clear"
                        disabled={!filters.search.input.length}
                        onclick={() => {
                            filters.search.input = ''
                            this.match_suggestions()
                        }}
                        name="backspace"
                        tip={'Remove Search Term'}
                    />
                </div>
                <div class="suggestions" id={'#suggestions'}>
                    {(() => {
                        let combined_index = 0
                        return suggestion_types.map(([suggestionTypeName, suggestionType]) => {
                            if (!this.matches[suggestionTypeName].length) return null
                            return (
                                <div className="suggestion-type">
                                    <div className="search-header">
                                        {$t(suggestionType.name)} ({this.matches[suggestionTypeName].length})
                                    </div>
                                    <div className="suggestion-matches"
                                        onclick={(e) => this.suggestion_delegate(e, true)}
                                        onmouseout={(e) => {
                                            if (!e.target.getAttribute('id')) return
                                        }}
                                        onmouseover={(e) => this.suggestion_delegate(e, false)}
                                    >

                                        {this.matches[suggestionTypeName].map(([, name, count,, parent]) => {
                                            const element = (
                                                <button
                                                    className={classes('suggestion', {
                                                        active: combined_index === $s.suggestions.selected.index,
                                                        selected: combined_index === $s.suggestions.selected.index,
                                                    })}
                                                    id={`suggestion-${combined_index}`}
                                                >

                                                    {(() => {
                                                        let elements:m.Vnode[] = []
                                                        if (parent) {
                                                            elements = [...[
                                                                <span className="parent children">{parent[1]}</span>,
                                                                <Icon className='icon-d' name='chevronRight'/>,
                                                            ]]
                                                        }
                                                        elements.push(this.format_suggestion(name, count))
                                                        return elements
                                                    })()}
                                                </button>
                                            )
                                            combined_index += 1

                                            return element
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    })()}
                </div>
            </div>
        )
    }
}
