import m from 'mithril'
import {BarMain, Button, Dropdown, Icon} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {SearchSite} from '@/components/search_site/search_site'
import {PricelistDownload} from '@/components/pricelist_download/pricelist_download'

export class BarMainPortal extends BarMain {

    data = proxy({
        orders: {
            toggle: false,
        },
        profile: {
            toggle: false,
        },
    })

    has_order_options() {
        if ($s.order.pending.length === 1) {
            if ($s.order.empty.length === 1) {
                if ($s.order.empty[0].artkey === $s.order.pending[0].artkey) {
                    return false
                }
            }
        }
        return true
    }

    slot_actions() {
        const route = m.route.get()
        return [
            <Icon
                className='btn-action'
                name='cartRight'
                onclick={() => m.route.set(`/orders/${$s.cart.artkey}/checkout?step=1`)}
                size="l"
                tip={$t('cart.checkout_tip')}
            />,

            <Dropdown
                className='dropdown-my-portal right'
                model={this.data.profile}
                trigger='click'
            >
                <div className="btn-dropdown">
                    <Icon
                        active={route.startsWith('/my-portal') || this.data.profile.toggle}
                        name='settings'
                        size="l"
                        tip={$t('my_portal.tip')}
                        type="info"
                    />
                </div>
                <div className='dropdown-body'>
                    <header>{$t('my_portal.label')}</header>
                    <Button
                        active={route.startsWith('/my-portal?tabId=profile')}
                        icon="profile"
                        link="/my-portal?tabId=profile"
                        onclick={() => this.data.profile.toggle = false}
                        text={$t('my_portal.profile')}
                        variant="menu"
                    />

                    <Button
                        active={route.startsWith('/my-portal?tabId=settings')}
                        icon="settings"
                        link="/my-portal?tabId=settings"
                        onclick={() => this.data.profile.toggle = false}
                        text={$t('my_portal.settings.label')}
                        variant="menu"
                    />

                    <Button
                        active={route.startsWith('/my-portal?tabId=security')}
                        icon="security"
                        link="/my-portal?tabId=security"
                        onclick={() => this.data.profile.toggle = false}
                        text={$t('my_portal.security.label')}
                        variant="menu"
                    />

                    <Button
                        active={route.startsWith('/my-portal?tabId=privacy')}
                        icon="incognito"
                        link="/my-portal?tabId=privacy"
                        onclick={() => this.data.profile.toggle = false}
                        text={$t('my_portal.privacy')}
                        variant="menu"
                    />

                    <Button
                        active={route.startsWith('/my-portal?tabId=promotions')}
                        disabled={!$s.promotion.active_grace}
                        icon="party"
                        link="/my-portal?tabId=promotions"
                        onclick={() => this.data.profile.toggle = false}
                        text={$t('my_portal.promotions')}
                        variant="menu"
                    />
                    <div className="divider"/>
                    <Button
                        icon="logout"
                        onclick={() => {
                            this.data.profile.toggle = false
                            $m.identity.logout()
                        }}
                        text={$t('my_portal.logout')}
                        variant="menu"
                    />
                </div>
            </Dropdown>,
        ]
    }

    slot_search(_vn:m.Vnode<any>) {
        return [
            <SearchSite/>,
            <PricelistDownload model={$s.offers.export.bar} with_text={true} icon={'excel'} />,
        ]
    }
}
