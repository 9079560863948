import m from 'mithril'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {
    Button,
    ButtonGroup,
    CollectionItems,
    CollectionView,
    FieldTextArea,
    Icon,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t, notifier} from '@bitstillery/common/app'
import {CollectionProxy} from '@bitstillery/common/lib/collection'

import {OrderItems} from './order_items/order_items'

import {CollectionProduct} from '@/components/collection/product'
import {$m, $s} from '@/app'

const collection_invalid = new CollectionProxy()
const collection_ready = new CollectionProxy()

export class VerifyCart extends MithrilTsxComponent<any> {

    data = proxy({
        actions: {},
        carousel_item: null,
    })

    cart_actions(cart_item) {
        const offer_item = $s.cart.offer_items[cart_item.case_artkey]
        return <div className="cart-actions">
            <ButtonGroup className="action-toggles" type="unset">
                <Button
                    active={!!cart_item.portal_comment}
                    className={cart_item.portal_comment ? 'dropdown-header' : 'inactive dropdown-header'}
                    icon={cart_item.portal_comment ? 'commentEdit' : 'comment'}
                    onclick={() => {
                        this.data.actions[cart_item.case_artkey].comment = !this.data.actions[cart_item.case_artkey].comment
                    }}
                    size="s"
                    type={cart_item.portal_comment ? 'info' : 'default'}
                    tip={() => {
                        return $t('checkout.comment_title')
                    }}
                    variant="toggle"
                />
            </ButtonGroup>

            {this.data.actions[cart_item.case_artkey]?.comment && <div className="action-body">
                <div className="action-title">
                    {$t('checkout.comment_title')}
                </div>
                <div className="action-content">
                    <FieldTextArea model={[cart_item, 'portal_comment']} />
                    <ButtonGroup className="action-controls">
                        {cart_item.portal_comment && <Button
                            className="mr-1"
                            disabled={!cart_item.portal_comment}
                            icon='trash'
                            type='default'
                            onclick={async() => {
                                cart_item.portal_comment = ''
                                this.data.actions[cart_item.case_artkey].comment = false
                                await $m.cart.update_cart({portal_comment: cart_item.portal_comment}, offer_item.case_artkey)
                                notifier.notify($t('notifications.comment_deleted'), 'warning', undefined, 'comment')
                            }}
                            tip={$t('checkout.comment_delete_tip')}
                        />}

                        <Button
                            disabled={!cart_item.portal_comment}
                            icon={cart_item.portal_comment ? 'checked' : 'plus'}
                            text={$t('checkout.comment_store')}
                            type='info'
                            onclick={async() => {
                                this.data.actions[cart_item.case_artkey].comment = false
                                await $m.cart.update_cart({portal_comment: cart_item.portal_comment}, offer_item.case_artkey)
                                notifier.notify($t('notifications.comment_stored'), 'info', undefined, 'comment')
                            }}
                        />
                    </ButtonGroup>
                </div>
            </div>}
        </div>
    }

    view(_vn:m.Vnode<any>) {
        const cart_items = Object.values($s.cart.items)
        const cart_grouped = {
            api: cart_items.filter((i:any) => $s.cart.errors.api[i.case_artkey]),
            price_up: cart_items.filter((i:any) => $s.cart.errors.price_up[i.case_artkey]),
            unavailable: cart_items.filter((i:any) => $s.cart.errors.unavailable[i.case_artkey]),
            less_available: cart_items.filter((i:any) => $s.cart.errors.less_available[i.case_artkey]),
            minimum_quantity: cart_items.filter((i:any) => $s.cart.errors.minimum_quantity[i.case_artkey]),
        }

        const cart_grouped_items = Object.entries(cart_grouped)

        collection_invalid.state.items.splice(0, collection_invalid.state.items.length, ...cart_items.filter((cart_item:any) => {
            const is_invalid = cart_grouped_items.some(([_k, v]) => v.find((k:any) => k.case_artkey === cart_item.case_artkey))
            const has_offer_item = cart_item.case_artkey in $s.cart.offer_items
            return is_invalid && has_offer_item
        }))

        collection_ready.state.items.splice(0, collection_ready.state.items.length, ...cart_items.filter((i:any) => {
            return !cart_grouped_items.some(([_k, v]) => v.find((k:any) => k.case_artkey === i.case_artkey))
        }))

        if (Object.keys($s.cart.items).length === 0) {
            return <div className="alert alert-info order-alert">
                <Icon className="icon-xxl" name="cart" />
                <div className="order-text">
                    <div className="title">{$s.cart.sales_order_items.length ? $t('cart.empty_booked_title') : $t('cart.empty_title')}</div>
                    <div className="description">
                        {$t('cart.empty_desc')}
                        <Button
                            icon='case'
                            onclick={() => {
                                m.route.set(get_route('/offers'))
                            }}
                            text={$t('order.empty_call_to_action')}
                            type='info'
                        />
                    </div>
                </div>
            </div>
        }

        return <div className="c-verify-cart">
            <div className="checkout-verify">
                {!!collection_invalid.state.items.length && <div className="soi-group warning">
                    <div className={classes('title', 'warning')}>
                        <Icon
                            className="mr-1"
                            name="info"
                            type="warning"
                            size="d"
                            tip={`${$t('cart.issues')} ${$t('cart.issues_action')}`}
                        />
                        {$t('cart.error', {count: collection_invalid.state.items.length})}
                    </div>

                    <CollectionView mode="list">
                        <CollectionItems
                            collection={collection_invalid}
                            item={(cart_item) => {
                                const offer_item = $s.cart.offer_items[cart_item.case_artkey]
                                return <CollectionProduct
                                    cart_actions={this.cart_actions.bind(this, cart_item)}
                                    key={offer_item.case_artkey}
                                    offer_item={offer_item}
                                    variant="cart"
                                />
                            }}
                        />
                    </CollectionView>
                </div>}

                {(collection_ready.state.items.length > 0) && <div className="soi-group success">
                    <div className="title success">
                        <Icon
                            className="mr-1"
                            name="checked"
                            type="success"
                            size="d"
                            tip={$t('cart.error_ok')}
                        />
                        {$t('cart.error_ok')}
                    </div>
                    <CollectionView mode="list">
                        <CollectionItems
                            collection={collection_ready}
                            item={(cart_item) => {
                                if (!this.data.actions[cart_item.case_artkey]) {
                                    this.data.actions[cart_item.case_artkey] = {
                                        comment: false,
                                    }
                                }
                                const offer_item = $s.cart.offer_items[cart_item.case_artkey]
                                return <CollectionProduct
                                    cart_actions={this.cart_actions.bind(this, cart_item)}
                                    key={cart_item.case_artkey}
                                    offer_item={offer_item}
                                    variant="cart"
                                />
                            }}
                        />
                    </CollectionView>
                </div>}
            </div>

            {$m.cart.data.stepper.selection === 2 && <div className="checkout-finish">
                <div className="soi-group">
                    <div className="title default">
                        <Icon name="cartRight" />
                        {$t('page.title.checkout-2', {order: $s.cart.artkey ? `S${$s.cart.artkey}` : $t('order.new')})}
                    </div>
                    <OrderItems filter="cart" />
                </div>
            </div>}
        </div>
    }
}
