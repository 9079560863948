import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {OrderItems} from './order_items/order_items'

export class FinalizeCart extends MithrilTsxComponent<any> {

    view(_vn:m.Vnode<any>) {
        return <OrderItems filter="all" />
    }
}
