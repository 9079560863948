import m from 'mithril'
import {Country} from '@bitstillery/common/components'
import {DataCard, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs, to_tags} from '@bitstillery/common/lib/specs'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'

function to_cpp_cpl(offer_item) {
    const strings = [] as string[]
    const show_btls_str = $s.cart.show_bottles ? 'btl' : 'cs'
    const btl_multiplier = $s.cart.show_bottles ? offer_item.case_number_of_bottles : 1
    if (offer_item.cases_per_pallet) {
        strings.push(`Pallet: ${offer_item.cases_per_pallet * btl_multiplier} ${show_btls_str}`)
    }
    if (offer_item.cases_per_pallet_layer) {
        strings.push(`Layer: ${offer_item.cases_per_pallet_layer * btl_multiplier} ${show_btls_str}`)
    }

    return strings.join(' / ')
}

interface ProductSpecsAttrs {
    offer_item: any
}

export class ProductSpecs extends MithrilTsxComponent<ProductSpecsAttrs> {

    view(vnode: m.Vnode<ProductSpecsAttrs>) {
        const offer_item = vnode.attrs.offer_item
        const bottle_gtin_filtered = offer_item.bottle_gtin_code?.filter((gtin) => gtin !== null)
        const bottle_gtin = bottle_gtin_filtered.length > 0 ? bottle_gtin_filtered : []
        return (
            <div className="c-product-specs">
                <div className="product-name">
                    <span>{offer_item.product_name}</span>
                    {$s.env.layout === 'mobile' && (offer_item.artkey in $s.product_photos && !!$s.product_photos[offer_item.artkey].length) &&
                    <Icon
                        className="mr-05"
                        onclick={() => {
                            $s.carousel.selection = offer_item.artkey
                        }}
                        name="image"
                        size="s"
                        type="info"
                    />}
                    {(() => {
                        const countries = $s.countries_of_origin[offer_item.artkey]
                        if (!Array.isArray(countries)) {
                            return null
                        }

                        if (countries.length === 1) {
                            const country = countries[0]
                            return <Country
                                country_code={country.country_of_origin}
                                tip={$t('product.stock_origin', {country: country.country_of_origin})}
                            />
                        } else if (countries.length > 1) {
                            return countries.map((country) => <Country
                                country_code={country.country_of_origin}
                                tip={$t('product.stock_origin_count', {
                                    count: $s.cart.show_bottles
                                        ? (country.number_of_cases_available * offer_item.case_number_of_bottles)
                                        : country.number_of_cases_available,
                                    units: $t(`unit.${$s.identity.user.price_preference}`, {count: country.number_of_cases_available}),
                                })}
                            />)
                        }
                    })()}
                </div>

                <div className="product-data">
                    <DataCard
                        model={{
                            data: [
                                {label: <Icon name="bottle" size="s" />, value: offer_item.product_category},
                                {label: <Icon name="clipboardText" size="s" />, value: to_specs(offer_item)},
                                {label: <Icon name="tagMultiple" size="s" tip={() => to_tags(vnode.attrs.offer_item)}/>, value: to_tags(vnode.attrs.offer_item)},
                                {label: <Icon name="gtin" size="s" />, value: bottle_gtin.join(', ')},
                                {label: <Icon name="weight" size="s" tip={$t('product.weight_tip')}/>, value: offer_item.case_weight ? `${Math.round(offer_item.case_weight * 100) / 100} kg` : ''},
                                {label: <Icon name="cpp" size="s" tip={$t('product.cpp_cpl_tip')}/>, value: to_cpp_cpl(offer_item)},
                            ].filter((i) => Array.isArray(i.value) ? !!i.value.length : i.value),
                        }}
                        variant="extra-dense"
                    />
                </div>
            </div>
        )
    }
}
