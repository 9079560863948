import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {Button, Icon} from '@bitstillery/common/components'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$t} from '@bitstillery/common/app'

import {PromotionTerms} from './terms'

import {$s} from '@/app'

class TokenItem extends MithrilTsxComponent<any> {

    view(vnode: m.Vnode<any>) {
        const promotion = $s.promotion
        const available_tokens = promotion.tokens_redeemable - promotion.tokens_selected
        return <div
            className={classes('token-item', {
                disabled: available_tokens < vnode.attrs.option.tokens_required,
                selected: vnode.attrs.option.id === vnode.attrs.current_item[0][vnode.attrs.current_item[1]],
            })}
            onclick={() => {
                vnode.attrs.current_item[0][vnode.attrs.current_item[1]] = vnode.attrs.option.id
                vnode.attrs.onchange(vnode.attrs.option)
            }}
        >
            <div className="description">
                <div>{vnode.attrs.option.title}</div>
                <div className="tokens-required">
                    {Array.from({length: vnode.attrs.option.tokens_required}).map((_, index) => (
                        <Icon key={index} className="icon-s icon-token" name={$s.promotion.token_icon} />
                    ))}
                </div>
            </div>
            <div className="actions">
                <div className="amount mr-1">{!!vnode.attrs.model[vnode.attrs.option.id] && vnode.attrs.model[vnode.attrs.option.id]}</div>
                <Button
                    disabled={!vnode.attrs.model[vnode.attrs.option.id]}
                    icon="minus"
                    onclick={() => {
                        vnode.attrs.model[vnode.attrs.option.id] -= 1
                        if (vnode.attrs.onafterupdate) {
                            vnode.attrs.onafterupdate(vnode.attrs.option)
                        }
                    }}
                    size="s"
                    variant="toggle"
                />
                <Button
                    disabled={vnode.attrs.option.tokens_required > available_tokens}
                    icon="plus"
                    onclick={() => {
                        if (!vnode.attrs.model[vnode.attrs.option.id]) {
                            vnode.attrs.model[vnode.attrs.option.id] = 0
                        }

                        vnode.attrs.model[vnode.attrs.option.id] += 1
                        if (vnode.attrs.onafterupdate) {
                            vnode.attrs.onafterupdate(vnode.attrs.option)
                        }
                    }}
                    size="s"
                    variant="toggle"
                />
            </div>
        </div>
    }
}

/**
 * A component to display and choose from a list of items that come with a cost.
 * It is used as Promotion gift picker, but is meant to be generic enough to be
 * reused in other places.
 */
export class TokenItems extends MithrilTsxComponent<any> {

    view(vnode: m.Vnode<any>) {
        return <div className="c-field-token-items">
            <div className="token-item-wrapper">
                <div className="gifts-label">
                    <span><Icon name="promotion" size="d" type="unset" />{$t('promotions.gifts.available')}</span>
                    <PromotionTerms />
                </div>
                {vnode.attrs.options.map((option:any) => <TokenItem
                    model={vnode.attrs.model}
                    current_item={vnode.attrs.current_item}
                    onchange={vnode.attrs.onchange}
                    onafterupdate={vnode.attrs.onafterupdate}
                    option={option}
                />)}
            </div>
            <div className="collected">
                <div className="description">
                    {$t('promotions.label.redeemable_tokens')}
                    <Icon className="ml-05" name="info" type="default" size="s" tip={() => {
                        return $t('promotions.eligible_multiple', {
                            turnover_draft: format_money_responsive($s.promotion.turnover_draft, undefined, $s.identity.user.currency),
                            turnover_redeemable: format_money_responsive($s.promotion.turnover_redeemable, undefined, $s.identity.user.currency),
                            token_value: format_money_responsive($s.promotion.token_value, undefined, $s.identity.user.currency),
                        })
                    }}/>
                </div>
                <div className="token-stats">
                    {(() => {
                        const token_placeholders = {
                            draft: Array.from({length: $s.promotion.tokens_draft - $s.promotion.tokens_redeemable}),
                            redeemable: Array.from({length: $s.promotion.tokens_redeemable - $s.promotion.tokens_selected}),
                            selected: Array.from({length: $s.promotion.tokens_selected}),
                        }

                        return [
                            token_placeholders.selected.length < 6 ?
                                token_placeholders.selected.map(() => <Icon className="token selected" name={$s.promotion.token_icon} size="l"/>) :
                                <div className="token-summarize selected">
                                    <Icon className="token" name={$s.promotion.token_icon} size="l" type="unset"/>
                                    <span className="amount">x{token_placeholders.selected.length}</span>
                                </div>,

                            token_placeholders.redeemable.length < 6 ?
                                token_placeholders.redeemable.map(() => <Icon className="token redeemable" name={$s.promotion.token_icon} size="l"/>) :
                                <div className="token-summarize redeemable">
                                    <Icon className="token" name={$s.promotion.token_icon} size="l" type="unset" />
                                    <span className="amount">x{token_placeholders.redeemable.length}</span>
                                </div>,

                            token_placeholders.draft.length < 6 ?
                                token_placeholders.draft.map(() => <Icon className="token draft" name={$s.promotion.token_icon} size="l"/>) :
                                <div className="token-summarize draft">
                                    <Icon className="token" name={$s.promotion.token_icon} size="l" type="unset"/>
                                    <span className="amount">x{token_placeholders.draft.length}</span>
                                </div>,

                        ]
                    })()}

                </div>

                <Button
                    className="btn-confirm"
                    disabled={($s.promotion.tokens_redeemable === 0 || $s.promotion.tokens_selected === 0)}
                    icon={$s.promotion.token_icon}
                    onclick={() => {
                        vnode.attrs.onsubmit()
                    }}
                    text={$t('promotions.redeem')}
                    type='success'
                />
            </div>
        </div>
    }
}
