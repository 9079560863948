import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, ButtonGroup, Dropdown, FieldCheckbox, FieldSelect, Icon, RadioGroup} from '@bitstillery/common/components'
import {$t, api, logger, store} from '@bitstillery/common/app'
import {format_iso_to_fixed_date_format} from '@bitstillery/common/lib/format.ts'
import {CollectionProxy} from '@bitstillery/common/lib/collection.ts'

import {$s} from '@/app'
import {ExportPortalPriceListRequest} from '@/factserver_api/fact2server_api'
import {collection} from '@/components/pages/offers/offer_list'

interface PricelistDownloadAttrs {
    model: any
    collection?: CollectionProxy
    icon: string
    with_text?: string
}

export class PricelistDownload extends MithrilTsxComponent<PricelistDownloadAttrs> {
    formatting = {
        alcohol: {
            options: [
                ['percentage', 'Percentage'],
                ['decimal', 'Decimal (0.x)'],
            ],
        },
        size: {
            options: [
                {value: 'l', label: 'l - Liters'},
                {value: 'cl', label: 'cl - Centiliters'},
                {value: 'ml', label: 'ml - Milliliters'},
            ],
        },
    }

    data = proxy({
        _selection: 0,
        formatting: this.formatting,
        uses_collection: false,
    })

    oninit(vnode: m.Vnode<PricelistDownloadAttrs>) {
        const collection = vnode.attrs.collection
        if (collection !== undefined) {
            this.data.uses_collection = true
            collection.state.selection.all = true // This way the select all products is already selected
            this.data.formatting = this.formatting
            this.data._selection = () => {
                if (collection.state.selection.all) {
                    return collection.state.total - collection.state.selection.ids.length
                } else {
                    return collection.state.selection.ids.length
                }
            }
        }
    }

    async download(model) {
        if (this.data.uses_collection) {
            const price_list_data: ExportPortalPriceListRequest = {
                include_case_artkeys: [],
                exclude_case_artkeys: [],
                search_terms: collection.state.query.search_terms,
                offer_hash: collection.state.query.offer_hash,
                availabilities: collection.state.query.availabilities,
                volume: collection.state.query.volume,
                alcohol_percentage: collection.state.query.alcohol_percentage,
                offer_item_type: collection.state.query.offer_item_type,
                packaging: collection.state.query.packaging,
                refill_status: collection.state.query.refill_status,
                category_artkeys: collection.state.query.category_artkeys,
                formatting: {
                    alcohol: model.formatting.alcohol.selection,
                    volume: model.formatting.size.selection,
                },
            }

            if (collection.state.selection.all) {
                price_list_data.exclude_case_artkeys = collection.state.items
                    .filter((item) => collection.state.selection.ids.includes(item.artkey))
                    .map((item) => item.case_artkey)

            } else if (collection.state.selection.ids.length) {
                price_list_data.include_case_artkeys = collection.state.items
                    .filter((item) => collection.state.selection.ids.includes(item.artkey))
                    .map((item) => item.case_artkey)
            } else {
                price_list_data.include_case_artkeys = []
            }
            await this.download_price_list(price_list_data, model)
        } else {
            const price_list_data: ExportPortalPriceListRequest = {
                include_case_artkeys: [],
                exclude_case_artkeys: [],
                search_terms: [],
                formatting: {
                    alcohol: model.formatting.alcohol.selection,
                    volume: model.formatting.size.selection,
                },
            }
            await this.download_price_list(price_list_data, model)
        }
    }

    async download_price_list(price_list_data: ExportPortalPriceListRequest, model) {
        const vendor = process.env.MSI_TITLE.replaceAll(' ', '-').toLowerCase()
        const file_name = `${vendor}-${$t('filters.pricelist.filename').replaceAll(' ', '-')}-${format_iso_to_fixed_date_format(new Date().toISOString())}`
        logger.info(`download ${file_name}`)
        // Store current export settings.
        store.save()
        model.loading = true

        await api.download('portal/offer-items/price-list', file_name, price_list_data)
        model.loading = false
        model.active = false
    }

    view(_vn:m.Vnode<PricelistDownloadAttrs>) {
        const model = _vn.attrs.model
        const collection = _vn.attrs.collection
        return <Dropdown
            className="c-pricelist-download collection-header-action"
            clickOutside={true}
            model={model}
            trigger="click"
        >
            <Button
                active={model.active}
                className={'btn-download'}
                onclick={() => {
                    model.active = true
                }}
                text={_vn.attrs.with_text ? $t('filters.pricelist.download') : ''}
                icon={_vn.attrs.icon}
                tip={$t('filters.pricelist.download_tip')}
                type="success"
            />

            <div className="dropdown-body">
                <div class="dropdown-body-header">
                    <Icon
                        className={classes({active: model.active})}
                        name={'download'}
                        type="unset"
                    />
                    <span className='title'>{$t('filters.pricelist.download')}</span>
                </div>
                <div class="options">
                    {this.data.uses_collection && <FieldCheckbox
                        className="select-toggle"
                        help={$t('filters.pricelist.products_selected', {
                            count: this.data._selection,
                            total: collection.state.total,
                        })}
                        model={[collection.state.selection, 'all']}
                        label={$t('filters.pricelist.select_all')}
                    />}
                    <RadioGroup
                        help={$t('filters.pricelist.alcohol_format_help')}
                        label={$t('filters.pricelist.alcohol_format')}
                        model={[model.formatting.alcohol, 'selection']}
                        options={this.data.formatting.alcohol.options}
                    />
                    <FieldSelect
                        help={$t('filters.pricelist.volume_format_help')}
                        label={$t('filters.pricelist.volume_format')}
                        model={[model.formatting.size, 'selection']}
                        options={this.data.formatting.size.options}
                    />

                    <ButtonGroup>
                        <Button
                            className="btn-download a-btn-download"
                            icon="cancel"
                            onclick={(e) => {
                                e.stopPropagation()
                                model.active = false
                                model.toggle = false
                            }}
                            text={$t('filters.pricelist.cancel_download')}
                            tip={() => {
                                if (model.loading) {
                                    return $t('filters.pricelist.download_tip_busy')
                                }
                                return $t('filters.pricelist.download_tip')
                            }}
                        />
                        <Button
                            className="btn-download a-btn-download"
                            disabled={model.loading || (this.data.uses_collection && !this.data._selection)}
                            icon="download"
                            loading={model.loading}
                            onclick={async() => {
                                plausible('pricelist_download', {
                                    props: {
                                        company_type: $s.identity.user.company_type,
                                        currency: $s.identity.user.currency,
                                        customs_visibility: $s.identity.user.customs_visibility,
                                        language: $s.identity.user.language,
                                        price_preference: $s.identity.user.price_preference,
                                    },
                                })
                                await this.download(model)
                            }}
                            text={$t('filters.pricelist.download')}
                            tip={() => {
                                if (model.loading) return $t('filters.pricelist.download_tip_busy')
                                return $t('filters.pricelist.download_tip')
                            }}
                            type="info"
                        />
                    </ButtonGroup>
                </div>
            </div>
        </Dropdown>
    }
}
