import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {watch} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

import {ProgressPromotion} from './progress'

import {$s} from '@/app'

export class IncentivePromotion extends MithrilTsxComponent<any> {
    watchers:any = []

    onbeforeremove(vnode) {
        return new Promise(function(resolve) {
            vnode.dom.addEventListener('animationend', resolve)
        })
    }

    oncreate(vnode) {
        this.watchers.push(watch($s.promotion, 'tokens_draft', () => {
            vnode.dom.classList.add('tokens-changed')
            setTimeout(() => {
                vnode.dom.classList.remove('tokens-changed')
            }, 3000)
        }))
    }

    onremove() {
        this.watchers.map((watcher) => watcher())
    }

    view(_vn:m.Vnode<any>) {
        return (
            <div className="c-incentive promotion">
                <a className="incentive-header" href="#!/my-portal/promotions">
                    <Icon
                        name={$s.promotion.token_icon}
                        size="l"
                        tip={() => {
                            return $t('promotions.eligible_multiple', {
                                turnover_draft: format_money_responsive($s.promotion.turnover_draft, undefined, $s.identity.user.currency),
                                turnover_redeemable: format_money_responsive($s.promotion.turnover_redeemable, undefined, $s.identity.user.currency),
                                token_value: format_money_responsive($s.promotion.token_value, undefined, $s.identity.user.currency),
                            })
                        }}
                        type="unset"
                    />
                    {$s.promotion.type === 'multi-order' && (
                        <span className="progress-invoiced">
                            <span className="redeemable">{$s.promotion.tokens_redeemable}</span>
                            <span className="preview">({$s.promotion.tokens_draft - $s.promotion.tokens_redeemable})</span>
                        </span>
                    )}
                </a>

                <div className="text">
                    <ProgressPromotion extended={false} />
                </div>
            </div>
        )
    }
}
