/* eslint-disable import/extensions */
import {merge_deep} from '@bitstillery/common/lib/utils'
import commonLocaleDe from '@bitstillery/common/locales/de.json'
import commonLocaleEn from '@bitstillery/common/locales/en-GB.json'
import commonLocaleEs from '@bitstillery/common/locales/es.json'
import commonLocaleFr from '@bitstillery/common/locales/fr.json'
import commonLocaleIt from '@bitstillery/common/locales/it.json'
import commonLocaleNl from '@bitstillery/common/locales/nl.json'

import portalLocaleDe from './de.json'
import portalLocaleFr from './fr.json'
import portalLocaleEn from './en-GB.json'
import portalLocaleEs from './es.json'
import portalLocaleNl from './nl.json'
import portalLocaleIt from './it.json'

export default {
    de: {translation: merge_deep(portalLocaleDe, commonLocaleDe)},
    'en-GB': {translation: merge_deep(portalLocaleEn, commonLocaleEn)},
    es: {translation: merge_deep(portalLocaleEs, commonLocaleEs)},
    fr: {translation: merge_deep(portalLocaleFr, commonLocaleFr)},
    it: {translation: merge_deep(portalLocaleIt, commonLocaleIt)},
    nl: {translation: merge_deep(portalLocaleNl, commonLocaleNl)},
}
