import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Tab, TabPanel, Tabs} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {$t} from '@bitstillery/common/app'

import {MyPortalPrivacy} from './privacy'
import {MyPortalProfile} from './profile'
import {MyPortalPromotions} from './promotions'
import {MyPortalSecurity} from './security'
import {MyPortalSettings} from './settings'

import {$s} from '@/app'

export class MyPortal extends MithrilTsxComponent<any> {

    data = proxy({
        tabs: {
            active: m.route.param('tabId') ? m.route.param('tabId') : 'profile',
        },
    })

    set_tab_context(section) {
        $s.page.icon = 'settings'

        const title = $t('my_portal.label')
        let subtitle

        if (section === 'profile') {
            subtitle = $t('my_portal.profile')
        } else if (section === 'settings') {
            subtitle = $t('my_portal.settings.label')
        } else if (section === 'security') {
            subtitle = $t('my_portal.security.label')
        } else if (section === 'privacy') {
            subtitle = $t('my_portal.privacy')
        } else if (section === 'promotions') {
            subtitle = $t('my_portal.promotions')
        }

        $s.page.title = `${title} - ${subtitle}`
    }

    view() {
        return (
            <div className="c-my-portal view view-fw">

                <Tabs data={this.data}>
                    <Tab
                        icon="profile"
                        id="profile"
                        link="/my-portal?tabId=profile"
                        onactivate={() => this.set_tab_context('profile')}
                        text={$t('my_portal.profile')}
                    >
                        <TabPanel>
                            <MyPortalProfile />
                        </TabPanel>
                    </Tab>
                    <Tab
                        icon="cog"
                        id="settings"
                        link="/my-portal?tabId=settings"
                        onactivate={() => this.set_tab_context('settings')}
                        text={$t('my_portal.settings.label')}
                    >
                        <TabPanel>
                            <MyPortalSettings />
                        </TabPanel>
                    </Tab>
                    <Tab
                        icon="security"
                        id="security"
                        link="/my-portal?tabId=security"
                        onactivate={() => this.set_tab_context('security')}
                        text={$t('my_portal.security.label')}
                    >
                        <TabPanel>
                            <MyPortalSecurity />
                        </TabPanel>
                    </Tab>
                    <Tab
                        onactivate={() => this.set_tab_context('privacy')}
                        icon="incognito"
                        id="privacy"
                        link="/my-portal?tabId=privacy"
                        text={$t('my_portal.privacy')}
                    >
                        <TabPanel>
                            <MyPortalPrivacy/>
                        </TabPanel>
                    </Tab>
                    <Tab
                        disabled={!$s.promotion.active_grace}
                        onactivate={() => this.set_tab_context('promotions')}
                        icon="promotion"
                        id="promotions"
                        link="/my-portal?tabId=promotions"
                        text={$t('my_portal.promotions')}
                    >
                        <TabPanel>
                            <MyPortalPromotions/>
                        </TabPanel>
                    </Tab>
                </Tabs>
            </div>
        )}
}
