import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Dialog} from '@bitstillery/common/components'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$t, store} from '@bitstillery/common/app'

import {$s} from '@/app'
import {CarouselGiveAway} from '@/components/promotion/carousel_giveaway'
import {PromotionTerms} from '@/components/promotion/terms'

export class DialogPromotion extends MithrilTsxComponent<any> {

    on_close() {
        $s.dialog.promotion = dayjs().toJSON()
        store.save()
    }

    view(_vn:m.Vnode<any>) {
        const days_left = Math.abs(dayjs().diff(dayjs($s.promotion.end_date), 'day'))
        return <Dialog
            icon="promotion"
            onclose={this.on_close.bind(this)}
            title={`${$s.promotion.slogan} (${$t('promotions.label.promotion')})`}
            className="c-dialog-promotion"
        >
            <CarouselGiveAway
                controls={false}
                indicators={false}
                slides={[{
                    url: '/img/promotions/banner.jpg',
                }, ...$s.promotion.gifts.map((option, i) => {
                    return {
                        description: option.description,
                        link: option.link,
                        title: option.title,
                        url: `/img/promotions/nl-action-${i}.jpg`,
                    }
                })]}
            />
            <div className="l-promotion">
                <div className="wrapper">
                    <div className="promotion-text">
                        {$t('promotions.label.dialog_subtitle', {
                            token_value: format_money_responsive($s.promotion.token_value, undefined, $s.identity.user.currency),
                            currency : $s.identity.user.currency,
                        })}
                    </div>
                    <Button
                        className="btn-cta"
                        icon="cart"
                        link='/offers'
                        onclick={() => {
                            this.on_close()
                        }}
                        text={$t('promotions.call_to_action')}
                        type="info"
                    />
                    <PromotionTerms />
                </div>
                {days_left <= 7 && (
                    <span className="incentive-hurry">{$t('promotions.incentive_hurry', {count: days_left})}</span>
                )}
            </div>
        </Dialog>
    }
}
