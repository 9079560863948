import m from 'mithril'
import {Changed, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {format_money_responsive} from '@bitstillery/common/lib/format'
import {$t} from '@bitstillery/common/app'

import {$m, $s} from '@/app'
import {IncentivePromotion, IncentiveTransport} from '@/components/incentives'

export class TotalCosts extends MithrilTsxComponent<any> {
    view(_vn:m.Vnode<any>) {
        const active_voucher = $m.cart.voucher_additional()
        const min_order = Number($s.identity.user.minimum_order_amount)

        return <div className={classes('c-total-costs', {
            collapsed: $s.panels.context.collapsed,
        })}>

            {(!!min_order || $s.promotion.active) && <div className="incentives">
                {!!min_order && <IncentiveTransport/>}
                {$s.promotion.active && [
                    <IncentivePromotion />,
                ]}
            </div>}

            <div className="cart subtotal">
                <div className="header">
                    <div className="description">
                        <Icon name="cartRight"/><span>{$t('checkout.cart')}</span>
                    </div>
                    <div className="amount">
                        {(() => {
                            const cart_amount = $m.cart.cart_amount()
                            const cart_amount_validated = $m.cart.cart_amount(true)
                            const collapsed = $s.panels.context.collapsed
                            if (cart_amount !== cart_amount_validated) {
                                return <Changed
                                    previous={cart_amount}
                                    current={cart_amount_validated}
                                />
                            }

                            return format_money_responsive(cart_amount_validated, collapsed, collapsed ? null : $s.identity.user.currency)
                        })()}
                    </div>
                </div>
                <div className="details">
                    <div className="detail">
                        <Icon name="case" size="xs"/>
                        <span>{$m.cart.cart_cases()}</span>
                    </div>
                    <div className="detail">
                        <Icon name="bottle" size="xs"/>
                        <span>{$m.cart.cart_bottles()}</span>
                    </div>
                </div>
            </div>

            {!!$s.cart.sales_order_items.length &&
            <div className="booked subtotal">
                <div className="header">
                    <div className="description">
                        <Icon name="booked"/><span>{$t('cart.soi.booked_title')}</span>
                    </div>
                    <div className="amount">
                        {(() => {
                            const amount = $m.cart.booked_amount()
                            const collapsed = $s.panels.context.collapsed
                            return format_money_responsive(amount, collapsed, collapsed ? null : $s.identity.user.currency)
                        })()}
                    </div>
                </div>
                <div className="details">
                    <div className="detail">
                        <Icon name="case" size="xs"/>
                        <span>{$m.cart.booked_cases()}
                        </span>
                    </div>
                    <div className="detail">
                        <Icon name="bottle" size="xs"/>
                        <span>{$m.cart.booked_bottles()}</span>
                    </div>
                </div>
            </div>}

            {(active_voucher && active_voucher.value_type === 'PERCENTAGE') && this.voucher_line(active_voucher)}

            <div className="vat subtotal">
                <div className="header">
                    <div className="description">
                        <Icon name="money"/><span>{$t('checkout.vat')}</span>
                    </div>
                    <div className="amount">
                        {(() => {
                            const collapsed = $s.panels.context.collapsed
                            const vat_amount = $m.cart.vat_total()
                            const vat_amount_validated = $m.cart.vat_total(true)
                            if (vat_amount !== vat_amount_validated) {
                                return <Changed
                                    previous={vat_amount}
                                    current={vat_amount_validated}
                                />
                            }
                            return format_money_responsive(vat_amount, collapsed, collapsed ? null : $s.identity.user.currency)
                        })()}
                    </div>
                </div>
            </div>

            {(active_voucher && active_voucher.value_type === 'FIXED') && this.voucher_line(active_voucher)}

            <hr/>

            <div className="total subtotal">
                <div className="header">
                    <div className="description">
                        <Icon name="money"/><span>{$t('checkout.total')}</span>
                    </div>
                    <div className="amount">
                        {(() => {
                            const collapsed = $s.panels.context.collapsed
                            const total_amount = $m.cart.total_amount()
                            const total_amount_validated = $m.cart.total_amount(true)
                            if (total_amount !== total_amount_validated) {
                                return <Changed
                                    previous={total_amount}
                                    current={total_amount_validated}
                                />
                            }
                            return format_money_responsive(total_amount, collapsed, collapsed ? null : $s.identity.user.currency)
                        })()}
                    </div>
                </div>
            </div>
        </div>
    }

    voucher_line(active_voucher) {
        return <div className="voucher subtotal">
            <div className="header">
                <div className="description">
                    <Icon name="voucherOn"/><span>{active_voucher.description}</span>
                </div>
                <div className="amount">
                    {(() => {
                        const collapsed = $s.panels.context.collapsed
                        const amount = Number(active_voucher.total)
                        return format_money_responsive(amount, collapsed, collapsed ? null : $s.identity.user.currency)
                    })()}
                </div>
            </div>
        </div>
    }
}
