import m from 'mithril'
import {classes, get_route} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, FieldCheckbox, FieldDate, FieldSelect, RadioGroup, Stepper} from '@bitstillery/common/components'
import {conditional, required, validation} from '@bitstillery/common/lib/validation'
import {PanelContext} from '@bitstillery/common/components'
import {$t, api, events, notifier, store} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {CartErrors} from './cart_errors'

import {$m, $s} from '@/app'
import {OrderItems} from '@/components/pages/orders/order_checkout/order_items/order_items'
import {TotalCosts} from '@/components/total_costs/total_costs'

export class PanelCart extends MithrilTsxComponent<any> {
    className = 'c-panel-cart'

    data = (() => {
        const $f = {
            t1: false,
            terms: false,
        }

        return proxy({
            // This is a metafield that is used to validate order conditions.
            _canOrder: () => {
                const form_errors = !!Object.values(this.$v).filter((i) => i && i._invalid).length
                // Booking an order is possible when there is a cart amount.
                // Finalizing an order is possible when there is either a
                // booked amount or a cart amount.
                return !form_errors && (
                    !$s.cart.finalize.selection && !!$m.cart.cart_amount() ||
                    $s.cart.finalize.selection && (!!$m.cart.booked_amount() || !!$m.cart.cart_amount())
                )
            },
            $f,
            loading: false,
            lockedGiftPlaceholder: '',
            stepper: {
                options: [
                    {
                        icon: 'storeSearch',
                        link: `#!${get_route('/offers')}`,
                        title: () => $t('page.title.checkout-0'),
                    },
                    {
                        icon: 'cartCheck',
                        link: `#!/orders/${$s.cart.artkey}/checkout?step=1`,
                        title: () => $t('page.title.checkout-1', {order: $s.cart.artkey ? `S${$s.cart.artkey}` : $t('order.new')}),
                        warning: () => Object.values($s.cart.errors).some((i) => Object.keys(i).length),
                    },
                    {
                        _disabled: () => {
                            return (
                                $m.cart.has_errors() ||
                                ($m.cart.cart_units() === 0 && $m.cart.booked_amount() === 0)
                            )
                        },
                        icon: 'cartRight',
                        link: `#!/orders/${$s.cart.artkey}/checkout?step=2`,
                        title: () => $t('page.title.checkout-2', {order: $s.cart.artkey ? `S${$s.cart.artkey}` : $t('order.new')}),

                    },
                ],
            },
        })
    })()

    $v = {
        delivery: validation([$s.cart.delivery, 'selection'], {
            validate: function(modelValue) {
                if (modelValue === 'asap' || (modelValue === 'date' && $s.cart.delivery.date)) {
                    this.message = ''
                    return false
                }

                this.message = $t('status.error.order.delivery_invalid')
                return this
            },
        }),
        promotion_choice: validation([$s.promotion, 'selection'], conditional(() => {
            return $s.promotion.unlocked && $s.promotion.type === 'single-order'
        }, required())),
        terms: validation([this.data.$f, 'terms'], required()),
        terms_price_up: validation([this.data.$f, 'terms_price_up'], conditional(() => {
            return (Object.values($s.cart.errors.price_up).length)
        }, required())),
        t1: validation([this.data.$f, 't1'], conditional(() => {
            return Object.values($s.cart.items).some((i:any) => i.case_customs_status === 'T1')
        }, required())),
    }

    oninit() {
        const pathname = m.parsePathname(m.route.get()) as any
        const url_params = Object.fromEntries(new URLSearchParams(pathname.params))
        const step = Number(url_params.step) ? Number(url_params.step) : 0
        events.once('order.loaded', () => {
            this.set_step(step)
        })
    }

    async confirm_cart_updates() {
        this.data.loading = true
        if ($m.cart.has_errors()) {
            await $m.cart.validate_cart_fix()
        } else {
            if (!m.route.get().startsWith(`/orders/${$s.cart.artkey}/checkout`)) {
                m.route.set(`/orders/${$s.cart.artkey}/checkout`)
            }
            $m.cart.data.stepper.selection += 1
        }
        this.data.loading = false
    }

    async confirm_order() {
        this.data.loading = true

        const _data:any = {
            delivery_date: $s.cart.delivery.selection === 'date' ? $s.cart.delivery.date : null,
            order_artkey: $s.cart.artkey,
            request_delivery: $s.cart.finalize.selection,
        }

        if ($s.promotion.type === 'single-order') {
            _data.promotion_choice = $s.promotion.current
        }
        // Order amount rounded to 1000 is a custom dimension to filter on for plausible;
        // after the order.config API call, the amount is empty.
        const revenue_range = this.revenue_range($m.cart.total_amount())

        const {result, success} = await api.post('order.confirm', _data) as any
        if (success) {
            this.data.loading = false
            if ($s.promotion.type === 'single-order') {
                // Clear any promotion choice that we made; it's already now added to the additional items.
                // A multi-order has its own sales order and is not affected by a single-order.
                $s.promotion.current = ''
            }
            // Clear ProductQuantity states after completing an order; otherwise
            // adding an item after booking will continue with the previously
            // select amount.
            $s.cart.items = {}

            if ($s.cart.finalize.selection) {
                notifier.notify($t('notifications.order_confirmed', {id: result.sales_order_artkey}), 'info', undefined, 'cartRight')
                plausible('order', {
                    props: {
                        company_type: $s.identity.user.company_type,
                        currency: $s.identity.user.currency,
                        customs_visibility: $s.identity.user.customs_visibility,
                        language: $s.identity.user.language,
                        price_preference: $s.identity.user.price_preference,
                        range: revenue_range,
                        step: 'order_finalized',
                    },
                })
                m.route.set(`/orders/${result.sales_order_artkey}?confirm_order`)
            } else {
                notifier.notify($t('notifications.order_booked', {id: result.sales_order_artkey}), 'info', undefined, 'cartRight')
                plausible('order', {
                    props: {
                        company_type: $s.identity.user.company_type,
                        currency: $s.identity.user.currency,
                        customs_visibility: $s.identity.user.customs_visibility,
                        language: $s.identity.user.language,
                        price_preference: $s.identity.user.price_preference,
                        range: revenue_range,
                        step: 'order_booked',
                    },
                })
                m.route.set(`/orders/${result.sales_order_artkey}`)
            }

            await Promise.all([
                $m.order.load_current_order(),
                $m.order.get_active_promotion(),
                $m.order.load_active_voucher(),
            ])
        } else {
            // Final validation failed; go back to the cart validation step.
            $m.cart.data.stepper.selection = 1
            $m.cart.validate_cart(result)

            if (result.message) {
                notifier.notify(result.message, 'warning', undefined, 'profile')
            } else {
                notifier.notify($t('notifications.order_issue'), 'warning', undefined, 'cartRight')
            }
            plausible('order', {
                props: {
                    company_type: $s.identity.user.company_type,
                    currency: $s.identity.user.currency,
                    customs_visibility: $s.identity.user.customs_visibility,
                    language: $s.identity.user.language,
                    price_preference: $s.identity.user.price_preference,
                    range: revenue_range,
                    step: 'order_invalid',
                },
            })
            this.data.loading = false
        }
    }

    revenue_range(amount: number): string {
        if (amount < 1000) return '0-1000'
        if (amount < 2500) return '1000-2500'
        if (amount < 5000) return '2500-5000'
        if (amount < 10000) return '5000-10000'
        if (amount < 20000) return '10000-20000'
        if (amount < 50000) return '20000-50000'
        if (amount < 100000) return '50000-100000'
        return '100000+'
    }

    async toggle_voucher(voucher) {
        await api.post('basket.modify_voucher', {
            order_artkey: $s.cart.artkey,
            voucher_code: voucher.code,
        })
        await $m.order.load_current_order()
    }

    async set_step(step: number, skip_verify = false) {
        let page_icon = 'cartCheck'

        const {result} = await api.post('order.validate', {order_artkey: $s.cart.artkey})
        await $m.cart.validate_cart(result)
        if (Object.values($s.cart.errors).some((i) => Object.keys(i).length) && step > 1) {
            $m.cart.data.stepper.selection = 1
            m.route.set(`/orders/${$s.cart.artkey}/checkout?step=1`)
        } else if (step === 1 && skip_verify) {
            // The verify step in the regular checkout flow, unless:
            // - the user is already on step 1, or
            // - the user is already on step 2 and the verify step fails.
            page_icon = 'cartRight'
            $m.cart.data.stepper.selection = 2
            m.route.set(`/orders/${$s.cart.artkey}/checkout?step=2`)
        } else {
            $m.cart.data.stepper.selection = step
            if (step > 0) {
                m.route.set(`/orders/${$s.cart.artkey}/checkout?step=${step}`)
            }
        }

        $s.page.title = $t(`page.title.checkout-${$m.cart.data.stepper.selection}`)
        $s.page.icon = page_icon
    }

    view(vnode: m.Vnode<any>) {
        const errors = $s.cart.errors
        const has_errors = $m.cart.has_errors()
        const tos_languages = ['nl', 'en']
        const tos_language = tos_languages.includes($s.language) ? $s.language : 'en'
        const active_voucher = $m.cart.voucher_additional()

        return <PanelContext
            className="c-panel-cart"
            keep_open={vnode.attrs.keep_open}
            minimizable={vnode.attrs.minimizable}
            title={vnode.attrs.title}
        >
            <div className="content">
                <div className="workflow">
                    <Stepper
                        model={$m.cart.data.stepper}
                        onChange={async(newStep: number) => {
                            await this.set_step(newStep)
                        }}
                        options={this.data.stepper.options}
                        tipPlacement="right"
                    />
                </div>

                {$m.cart.data.stepper.selection !== 2 && <OrderItems filter={(() => {
                    if ($m.cart.data.stepper.selection === 0) return 'all'
                    return 'cart'
                })()}/>}

                <TotalCosts/>

                {$m.cart.data.stepper.selection === 2 && !!$s.cart.vouchers.available.length &&
                <div className="vouchers element">
                    <div className="label">{$t('checkout.vouchers.label')}</div>
                    <div className="item">
                        {(() => {
                            const voucher_code = $m.cart.voucher_additional()?.description
                            return $s.cart.vouchers.available.map((voucher: any) => <Button
                                className={voucher.code === voucher_code ? 'active' : ''}
                                disabled={$m.cart.cart_units() === 0 && !$s.cart.sales_order_items.length && !active_voucher}
                                icon={voucher.code === voucher_code ? 'voucherOn' : 'voucher'}
                                onclick={() => this.toggle_voucher(voucher)}
                                text={voucher.code}
                                type='info'
                            />)
                        })()}
                    </div>
                </div>}

                {$m.cart.data.stepper.selection === 1 && <CartErrors data={this.data}/>}

                {$m.cart.data.stepper.selection === 0 && <div className="confirmations">
                    <div className="confirmation active">
                        <Button
                            className="btn-confirm-step"
                            disabled={(
                                this.data.loading ||
                            ($m.cart.cart_units() === 0 && $m.cart.booked_amount() === 0)
                            ) ? 'disabled' : ''}
                            onclick={() => {
                                this.set_step(has_errors ? 1 : 2)
                            }}
                            loading={this.data.loading}
                            icon={has_errors ? 'cartCheck' : 'cartRight'}
                            text={has_errors ? $t('checkout.status.step1_title') : $t('checkout.status.step2_title')}
                            type='success'
                        />
                    </div>
                </div>}

                {$m.cart.data.stepper.selection === 1 && <div className="confirmations">
                    {!!Object.keys(errors.price_up).length && <div className="acknowledge">
                        <FieldCheckbox
                            disabled={!$s.cart.artkey}
                            label={
                                <span>{m.trust($t('cart.price_up_agree', {
                                    count: Object.keys(errors.price_up).length,
                                }))}</span>
                            }
                            help={$t('cart.price_up_agree_help', {count: Object.keys(errors.price_up).length})}
                            model={[this.data.$f, 'terms_price_up']}
                            validation={this.$v.terms_price_up}
                        />
                    </div>}
                    <div className="confirmation active">
                        <Button
                            className="btn-confirm-step"
                            disabled={(
                                Object.keys(errors.api).length ||
                            this.data.loading ||
                            this.$v.terms_price_up._invalid ||
                            ($m.cart.cart_units() === 0 && $m.cart.booked_amount() === 0)
                            )}
                            loading={this.data.loading}
                            link={`/orders/${$s.cart.artkey}/checkout?step=2`}
                            icon={has_errors ? 'cartCheck' : 'cartRight'}
                            onclick={() => this.confirm_cart_updates()}
                            text={$t(has_errors ? 'checkout.status.step1_button_fix' : 'checkout.status.step1_button')}
                            type={(() => {
                                if (Object.keys(errors.api).length) return 'danger'
                                if (has_errors) return 'info'
                                return 'success'
                            })()}

                        />
                    </div>
                </div>}

                {$m.cart.data.stepper.selection === 2 && <div className="confirmations">
                    {/*
                Only single order promotions have a direct selection of promotion items, because
                the promotion items are added as additional items to the current sales order. Promotions of
                type multi-order may choose items during the promotion period, spanning multiple sales orders.
                */}
                    {$s.promotion.active && $s.promotion.type === 'single-order' && <div className="confirmation active">
                        <FieldSelect
                            disabled={!$s.promotion.unlocked}
                            help={$t('promotions.gifts.choose_help')}
                            label={$s.promotion.unlocked ? $t('promotions.gifts.choose') : $t('promotions.gifts.choose')}
                            model={$s.promotion.unlocked ? [$s.promotion, 'selection'] : [this.data, 'lockedGiftPlaceholder']}
                            options={$s.promotion.gifts.map((i: any) => ({value: i.id, label: i.description}))}
                            placeholder={$s.promotion.unlocked ? $t('promotions.gifts.unlocked_placeholder') : $t('promotions.gifts.locked_placeholder')}
                            validation={this.$v.promotion_choice}
                        />
                    </div>}
                    <div className="confirmation active">
                        <RadioGroup
                            className='active'
                            disabled={$s.cart.loading || !$s.cart.artkey}
                            help={() => {
                                let description
                                if ($s.cart.finalize.selection) {
                                    description = $t('checkout.status.finalize_help')
                                } else {
                                    description = $t('checkout.status.book_help')
                                }
                                return description
                            }}
                            label={$t('order.completion')}
                            model={[$s.cart.finalize, 'selection']}
                            options={$s.cart.finalize.options}
                            translate={{prefix: ''}}
                        />
                    </div>

                    <div className={classes('confirmation', {active: $s.cart.finalize.selection})}>
                        <RadioGroup
                            disabled={$s.cart.loading || !$s.cart.artkey}
                            label={$t('order.delivery')}
                            model={[$s.cart.delivery, 'selection']}
                            options={$s.cart.delivery.options}
                            translate={{prefix: ''}}
                        />

                        {$s.cart.delivery.selection === 'date' && (
                            <FieldDate
                                label={$t('order.delivery_date')}
                                date_picker_options={{
                                    minDate: new Date(),
                                }}
                                model={$s.cart.delivery}
                            />
                        )}
                    </div>

                    <div className="confirmation active terms element">
                        <FieldCheckbox
                            disabled={!$s.cart.artkey}
                            label={
                                process.env.MSI_THEME === 'msp' &&
                            <span>{m.trust($t('order.agree_tos', {
                                link: `/pdf/terms-and-conditions-${tos_language}-${process.env.MSI_THEME}.pdf`,
                            }))}</span>
                            }
                            model={[this.data.$f, 'terms']}
                            validation={this.$v.terms}
                        />

                        {Object.values($s.cart.items).some((i: any) => i.case_customs_status === 'T1') && <FieldCheckbox
                            disabled={has_errors || !$s.cart.artkey}
                            label={$t('order.agree_t1')}
                            model={[this.data.$f, 't1']}
                            validation={this.$v.t1}
                        />}

                        <Button
                            className="btn-confirm-step"
                            disabled={!this.data._canOrder}
                            icon='checked'
                            loading={this.data.loading}
                            onclick={() => this.confirm_order()}
                            text={$s.cart.finalize.selection ? $t('checkout.status.step2_button') : $t('checkout.status.step2_button_book')}
                            type='success'
                        />
                    </div>
                </div>}
                <Button
                    className={classes('toggle', {
                        collapsed: $s.panels.context.collapsed,
                    })}
                    icon={$s.panels.context.collapsed ? 'menuOpen' : 'chevronRight'}
                    onclick={() => {
                        $s.panels.context.collapsed = !$s.panels.context.collapsed
                        store.save()
                    }}
                    variant="toggle"
                />
            </div>
        </PanelContext>
    }
}
